import styled from 'styled-components';
import { Size } from '@components/type';

export const Wrapper = styled.div<{ size: Size; isShadow: boolean; background: string; border: string }>`
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background-color: ${props => props.background ? props.background : '#dd43eb'};
  border-radius: 4px;
  border: 1px solid ${props => props.border ? props.border : '#d200bd'};
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;

  ${(props) =>
    props.size === 'small' &&
    `
    line-height: 10px;
    padding: 4px 6px;
    height: 18px;
  `}

  ${(props) =>
    props.size === 'medium' &&
    `
    min-width: 61px;
    line-height: 22px;
    padding: 6px;
    height: 24px;
    `}

  ${(props) =>
    props.size === 'fit' &&
          `
    line-height: 16px;
    padding: 4px 6px;
    `}
    
  ${(props) =>
    props.isShadow &&
    `
    box-shadow: 0px 2.21739px 8.86957px rgba(0, 0, 0, 0.24);
  `}
  font-family: 'Pretendard';
  font-style: normal;
  font-size: 12px;
  color: #FFFFFF;
`;
