import { LanguageCode, LANGUAGE_CODE } from 'src/constants';

type CurrencyUnit = {
  unit: string;
  position: 'left' | 'right';
};

const KRW: CurrencyUnit = {
  unit: '원',
  position: 'right',
};
const ABBREVIATED_KRW: CurrencyUnit = {
  unit: '￦',
  position: 'left',
};
const USD: CurrencyUnit = {
  unit: '$',
  position: 'left',
};
const JPY: CurrencyUnit = {
  unit: '円',
  position: 'right',
};
const CNY: CurrencyUnit = {
  unit: '¥',
  position: 'left',
};

export const getCurrencyUnit = (languageCode: LanguageCode, isAbbreviated?: boolean) => {
  if (languageCode === LANGUAGE_CODE.KO) {
    if (isAbbreviated) return ABBREVIATED_KRW;
    return KRW;
  } else if (languageCode === LANGUAGE_CODE.EN) {
    return USD;
  } else if (languageCode === LANGUAGE_CODE.JP) {
    return JPY;
  } else if (languageCode === LANGUAGE_CODE.CN) {
    return CNY;
  }
  throw new Error('지원하지 않는 언어입니다.');
};
