import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Styled = {
  Contents: styled.div<{ width?: width; marginTop?: number; marginBottom?: number }>`
    width: ${(props) => (props.width ? props.width : 900)}px;
    margin: 40px auto 0px;
    ${(props) => props.marginBottom !== undefined && `margin-bottom: ${props.marginBottom}px;`}
    ${(props) => props.marginTop !== undefined && `margin-top: ${props.marginTop}px;`}
    ${(props) =>
    props.width === 1176 &&
      `
      @media (min-width: 900px) and (max-width: 1176px) {
        width: 100%;
      }
      @media (max-width: 900px) {
        width: 900px;
      }
    `}
  `,
};

type width = 1176 | 900;

type Props = {
  marginTop?: number;
  marginBottom?: number;
  className?: string;
  width?: width;
  children?: ReactNode;
};

export default function Contents({ width, marginTop, marginBottom, className, children }: Props) {
  return (
    <Styled.Contents className={className} width={width} marginTop={marginTop} marginBottom={marginBottom}>
      {children}
    </Styled.Contents>
  );
}
