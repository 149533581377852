import React from 'react';
import { isChrome, isIE } from 'react-device-detect';
import Outdated from './Outdated';
import Recommend from './Recommend';

/**
 * 브라우저 호환성 체크
 * @warning 해당 코드는 모든 브라우저에 호환되는 코드로만 작성되어야 합니다.
 * @author livet
 * @returns null || 호환 브라우저 안내 컴포넌트 || 지원 종료 브라우저 안내 컴포넌트
 */
export default function UnsupportedBrowser() {
  if (isIE) {
    // 지원종료 브라우저인 경우
    return <Outdated />;
  }

  if (!isChrome) {
    // 크롬이 아닐 경우
    return <Recommend />;
  }

  return null;
}
