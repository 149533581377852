import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

type Props = Omit<IconProps, 'weight'>;

export default function QuestionMarkIcon({ color, className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill={color ? color : '#333333'} />
        <path
          d="M9.50001 9.31237C9.50001 8.81792 9.64662 8.33456 9.92132 7.92344C10.196 7.51232 10.5865 7.19189 11.0433 7.00267C11.5001 6.81345 12.0028 6.76396 12.4877 6.86042C12.9727 6.95688 13.4181 7.19497 13.7678 7.5446C14.1174 7.89424 14.3555 8.33969 14.452 8.82465C14.5484 9.3096 14.4989 9.81226 14.3097 10.2691C14.1205 10.7259 13.8001 11.1163 13.3889 11.391C12.9778 11.6657 12.4945 11.8124 12 11.8124V13.9999"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path d="M12 17.1875V15.6875" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
      </svg>
    </Styled.IconWrapper>
  );
}
