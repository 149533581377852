import { useCallback, useMemo } from 'react';
import useCookie from 'react-use/lib/useCookie';

// 장바구니에 대한 custom hook 입니다.
export const useCartOnSession = () => {
  const [cartsOnSessionString, setCartsOnSessionString] = useCookie('ACON_carts');
  const cartsOnSession = useMemo(() => (cartsOnSessionString ? JSON.parse(cartsOnSessionString) : []), [cartsOnSessionString]);
  const setCartsOnSession = useCallback((arg) => setCartsOnSessionString(typeof arg === 'object' ? JSON.stringify(arg) : arg), [cartsOnSessionString]);
  return [cartsOnSession, setCartsOnSession];
};
