import React from 'react';
import { EXTENSION_CODE } from 'src/constants';
import { ExtensionBadgeContainer, ExtensionBadgeContainerV2 } from './styled';

interface props {
  name: any;
  size?: number;
}

export const ExtensionBadge: React.FC<props> = ({ name, size }) => {
  // skp, blend, etc 3개로 구분
  const BadgeType = [EXTENSION_CODE.SKP, EXTENSION_CODE.BLEND].includes(name) ? name : EXTENSION_CODE.ETC;

  return (
    <ExtensionBadgeContainer size={size} type={BadgeType}>
      {name}
    </ExtensionBadgeContainer>
  );
};

// [TODO] : badge 스타일이 2개여서 생긴 컴포넌트, 추후 통합 될 예정
export const ExtensionBadgeV2: React.FC<props> = ({ name, size }) => {
  // skp, blend, etc 3개로 구분
  const BadgeType = [EXTENSION_CODE.SKP, EXTENSION_CODE.BLEND].includes(name) ? name : EXTENSION_CODE.ETC;

  return (
    <ExtensionBadgeContainerV2 size={size} type={BadgeType}>
      {name}
    </ExtensionBadgeContainerV2>
  );
};
