import React from 'react';
import { useTheme } from 'styled-components';

export default function Searcher({ className = '', ...rest }) {
  const theme = useTheme();
  const color = theme.colors.desktopSearchIcon;
  return (
    <div className={className} {...rest}>
      <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2001 12.1407C10.2193 12.8392 9.01943 13.2499 7.72363 13.2499C4.40992 13.2499 1.72363 10.5636 1.72363 7.24994C1.72363 3.93623 4.40992 1.24994 7.72363 1.24994C11.0373 1.24994 13.7236 3.93623 13.7236 7.24994C13.7236 8.5458 13.3128 9.74571 12.6143 10.7265L15.2236 13.3357C15.6142 13.7262 15.6142 14.3594 15.2236 14.7499C14.8331 15.1405 14.1999 15.1405 13.8094 14.7499L11.2001 12.1407ZM11.7236 7.24994C11.7236 9.45908 9.93277 11.2499 7.72363 11.2499C5.51449 11.2499 3.72363 9.45908 3.72363 7.24994C3.72363 5.0408 5.51449 3.24994 7.72363 3.24994C9.93277 3.24994 11.7236 5.0408 11.7236 7.24994Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
