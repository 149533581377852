import React from 'react';
import styled from 'styled-components';
import { IHeaderContainerProps, IHeaderContentContainerProps, IHeaderTopBannerContainer } from './Header.types';

export const HeaderContainer = styled.div<IHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  ${(props) => `height: ${props.bannerHeight}px`};
  background-color: ${(props: any) => props.theme.colors.gnbBg};
`;

export const HeaderContentContainer = styled.div<IHeaderContentContainerProps>`
  background-color: ${(props) => props.theme.colors.gnbBg};
  width: 100%;
  z-index: 99;
  position: fixed;
  top: ${(props) => props.top}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeaderGnbContainer = styled.div<React.CSSProperties>`
  max-width: 1444px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeaderTopBannerContainer = styled.div<IHeaderTopBannerContainer>`
  width: 100%;
  ${(props) =>
    props.isMobile &&
    `
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  `}
`;
