import React, { useState, ComponentProps } from 'react';
import styled from 'styled-components';
import { CustomText } from '@components/ui';
import { Validator } from '@lib/form';
import { NormalCheckbox } from '@components/ui/Checkbox';
import { RightArrowIcon } from 'src/assets/icons';
import { Validation, InputState } from './ValidationInput';

export const CheckboxContainer = styled.div({
  display: 'flex',
  alignItems: 'top',
  justifyContent: 'space-between',
  ':last-child': { marginBottom: 0 },
});

export const MessagedCheckboxContainer = styled.div({ marginBottom: '12px', ':last-child': { marginBottom: 0 } });

export const Checkbox = styled.input.attrs({ type: 'checkbox' })(({ value }: ComponentProps<'input'>) => {
  // (구)DB에 저장된 특이한 데이터 포맷에 따르기 위한 로직입니다.
  // DB 이전시 스키마 변경과 함께 수정되어야 합니다.
  let checked: any = value;
  if (value === 'y') checked = true;
  if (value === 'n') checked = false;

  return {
    appearance: 'none' as const,
    flex: '0 0 16px',
    margin: '4px 0',
    height: '16px',
    backgroundColor: 'white',
    border: checked ? '1px #333 solid' : '1px #ccc solid',
    position: 'relative' as const,
    cursor: 'pointer',
    ':before': checked && {
      content: '""',
      position: 'absolute' as const,
      width: '1.2ex',
      height: '0.8ex',
      top: '1px',
      left: '2px',
      border: '1.5px solid #333333',
      transform: 'rotate(-45deg)',
      borderTop: 'none',
      borderRight: 'none',
    },
  };
});

export const CheckboxLabelContainer = styled.div({ paddingLeft: '10px' });

export const CheckboxLabel = styled.label({
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '100%',
  height: '16px',
  color: '#333',
  textAlign: 'left',
});

export const CheckboxInfo = styled(CheckboxLabel)<{ required?: boolean }>`
  padding-left: 10px;
  font-size: 12px;
  color: ${(props) => (props.required ? '#F300BA' : '#808080')};
`;

export const CheckBoxLabelContainer = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
});

export const LinkContainer = styled.a({
  flex: '0 0 60px',
  margin: '2px 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 20,
  cursor: 'pointer',
});

interface Props extends ComponentProps<typeof Checkbox> {
  style?: React.CSSProperties;
  name?: string;
  state?: InputState;
  onClick?: React.MouseEventHandler;
  label: string;
  info?: string;
  required?: boolean;
  viewAll?: string;
  href?: string;
  validator?: Validator;
}

const LabeledCheckbox: React.FC<Props> = (props: Props) => {
  const _state: InputState = {
    value: useState(),
    valid: useState(true),
    info: useState(''),
  };

  const { style, name, required, state = _state, label, info, viewAll, href, validator, onClick, ...rest } = props;

  const [value, setValue] = state.value;
  const [valid, setValid] = state.valid;
  const [message, setMessage] = state.info;

  const color: string = valid === false ? '#f300ba' : '#3a3e94';

  const _onClick = async (e: React.MouseEvent) => {
    const target = e.target as HTMLInputElement;
    const newValue = target.value;
    setValue(newValue === 'n');

    if (validator) {
      const r = await validator(newValue);
      setValid(r.valid);
      setMessage(r.info);
    }

    if (onClick) onClick(e);
  };

  return (
    <MessagedCheckboxContainer>
      <CheckboxContainer>
        <CheckBoxLabelContainer>
          <NormalCheckbox onClick={_onClick} htmlFor={name} value={value ? 'y' : 'n'} {...rest} />
          <input type="hidden" value={value ? 'y' : 'n'} name={name} {...rest} />
          <CheckboxLabelContainer>
            <CheckboxLabel style={style}>{label}</CheckboxLabel>
            {info && <CheckboxInfo required={required === true}>{info}</CheckboxInfo>}
          </CheckboxLabelContainer>
        </CheckBoxLabelContainer>
        {viewAll && href && (
          <LinkContainer href={href} target="_blank">
            <CustomText tagName="span" size={12} color="#808080">
              {viewAll}
            </CustomText>
            <RightArrowIcon size={12} color={'#808080'} />
          </LinkContainer>
        )}
      </CheckboxContainer>
      {message && <Validation color={color}>{message}</Validation>}
    </MessagedCheckboxContainer>
  );
};

export default LabeledCheckbox;
