import styled from 'styled-components';

export const PromotionBannerContainer = styled.div<{ color: string; isBorder: boolean; borderColor: string }>`
  display: flex;
  justify-content: center;
  ${(props) => `
    background-color: ${props.color}; 
    ${props.isBorder ? `border-bottom: 1px ${props.borderColor} solid;` : ''}
  `}
`;

export const PromotionBannerCenter = styled.div`
  width: 1444px;
  margin: 0 auto;
  overflow-x: clip;
  display: flex;
  justify-content: center;
  & > img,
  & > map {
    zoom: 0.5;
  }
`;

export const PromotionBannerImage = styled.img`
  position: relative;
  max-width: unset;

  ${(props) => props.theme.breakpoints.down('lg')} {
    max-width: 100%;
  }
`;
