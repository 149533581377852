import styled, { keyframes } from 'styled-components';
import { colors } from '@public/theme';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const CardPromotionContainer = styled.div<{ bgColor?: string; isOld?: boolean }>`
  padding: ${(props) => (props.isOld ? '56px 0' : '48px 0px 40px 0px')};
  border-bottom: 1px ${colors.gray.c1} solid;
  background-color: ${(props) => props.bgColor || '#fafafd'};
  margin-bottom: 48px;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding-top: 36px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 24px;
  }
  .sizeWrapper {
    & > div {
      padding: 0;
    }
  }
`;

export const CardGroupSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardSkeleton = styled.div`
  width: 276px;
  height: 328px;
  background-image: linear-gradient(lightgray 172px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0),
    linear-gradient(lightgray 16px, transparent 0);
  background-repeat: repeat-y;
  background-size: 100% 328px, 180px 304px, 90px 304px, 32px 304px;
  background-position: 0px 0px, 0px 188px, 188px 214px, 0px 214px;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
`;
