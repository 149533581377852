export const DEFAULT_GAP = {
  sm: 24,
  md: 24,
  lg: 24,
};
export const DEFAULT_COLUMNS = {
  sm: 4,
  md: 8,
  lg: 12,
};

export const DEFAULT_MARGIN = {
  sm: 16,
  md: 20,
  lg: 74,
};
