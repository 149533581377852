import React from 'react';
import { CustomIcon } from '@components/ui/Icon';
import { CustomLink } from '@components/ui';
import { PATH_PAGE } from '../../../src/routes/paths';
import { IconContainer, IconImage, IconImageWrapper, IconTitle } from './styled';

export const Icon = ({ className, name, code, ...rest }) => {
  return (
    <IconContainer {...rest} data-mixpanel-action="click" data-mixpanel-evt="Click Main Category Icon" data-mixpanel-category={code}>
      <CustomLink href={`${PATH_PAGE.category}/${code}`} className={className}>
        {(() => {
          // 카테고리 현재 규칙의 경우, #{imageName}#{categoryName} 으로 되어있어 이미지 태그로 치환해주어야 합니다.

          // 카테고리명 정규식 (# 으로 묶여진 항목은 이미지 파일명)
          const cateRegex = /#(.*?)#/g;
          // 이미지 명
          let imgName = name.match(cateRegex);
          // 이미지명 확보
          imgName = imgName ? imgName[0].replace(/#/g, '') : imgName;

          const url = `/icons/category/main/${imgName}.png`;
          return (
            <div style={{ display: 'block' }}>
              <IconImageWrapper>
                <IconImage>
                  <CustomIcon src={url} alt="categoryIcon" title="categoryIcon" width="100%" height="100%" />
                </IconImage>
              </IconImageWrapper>
              <IconTitle>{name.replace(cateRegex, '')}</IconTitle>
            </div>
          );
        })()}
      </CustomLink>
    </IconContainer>
  );
};
