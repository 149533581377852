import styled from 'styled-components';

export const GroupWrapper = styled.div`
  margin-top: 28px;

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;

export const GroupTitle = styled.div<{ isPopul: boolean }>`
  font-size: 15px;
  line-height: 100%;
  color: #333;
  margin-bottom: 12px;
  font-weight: 700;
  ${(props) => `padding: ${props.isPopul ? '0px 8px 0px 5px' : '0px 8px'};`}
`;

export const GroupContents = styled.div``;

export const GroupItem = styled.div`
  font-size: 14px;
  line-height: 100%;
  padding: 7px 8px;
  cursor: pointer;
`;

export const GroupItemLink = styled.a`
  display: block;
  width: 100%;
`;

export const GroupItemRank = styled.span`
  margin-right: 3px;
  font-weight: 500;

  ${(props) => `
        color: ${props.color};
    `}
`;
