import React from 'react';
import { Transition } from 'react-transition-group';
import * as Styled from './styled';
import { SubContainerItem } from './SubContainerItem';

export const SubContainer = ({ data, activeId, onClick }) => {
  const isActive = Boolean(activeId);

  return (
    <Transition in={isActive} timeout={300}>
      {(subState) => (
        <Styled.SubContainer state={subState}>
          {isActive ? (
            (() => {
              return data.map((item, i) => <SubContainerItem key={i} item={item} onClick={onClick} />);
            })()
          ) : (
            <div></div>
          )}
        </Styled.SubContainer>
      )}
    </Transition>
  );
};
