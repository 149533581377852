import styled from 'styled-components';

export const ShadedArea = styled.div({
  backgroundColor: '#fafafd',
  border: '1px #e0e0e0 solid',
  boxSizing: 'border-box',
  padding: '24px 40px',
});

export default ShadedArea;
