import React from 'react';
import * as Styled from './styled';

export const Skeleton: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.SkeletonContainer>
        <Styled.SkeletonParent>
          <Styled.SkeletonParentTitle />
          {(() => {
            // 1뎁스 카테고리 아이템 나열
            const skeletonItemWidth = [220, 200, 190, 210, 200, 200, 220, 200, 200, 180, 200, 180];
            return skeletonItemWidth.map((x, i) => <Styled.SkeletonParentItem key={i} data-width={x} />);
          })()}
          <Styled.SkeletonParentTitle className={'mt-8'} />
          {(() => {
            // 1뎁스 카테고리 아이템 나열
            const skeletonItemWidth = [120, 120, 100, 110];
            return skeletonItemWidth.map((x, i) => <Styled.SkeletonParentItem key={i} data-width={x} />);
          })()}
        </Styled.SkeletonParent>
      </Styled.SkeletonContainer>
    </Styled.Wrapper>
  );
};
