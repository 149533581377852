import styled, { keyframes } from 'styled-components';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const CategorySkeletonContainer = styled.div`
  padding: 5px 10px;
`;

export const SkeletonItem = styled.div<{ widthSize: number }>`
  height: 15px;
  border-radius: 5px;
  background-image: linear-gradient(lightgray 30px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  margin-bottom: 15px;

  ${(props) => `width: ${props.widthSize}px;`}
`;
