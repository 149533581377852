import styled from 'styled-components';

export const IconContainer = styled.div`
  text-align: center;
  cursor: pointer;
`;

export const IconImageWrapper = styled.div`
  width: 64px;
  height: 64px;
  border: 1px solid #eee;
  border-radius: 12.4444px;
  background-color: #f6f6fb;
  margin: 0 auto;
  box-shadow: 0px 1px 1px #eaeaea;
  transition: 0.15s;
  position: relative;

  ${IconContainer} : hover &:first-child {
    background-color: #ffe2ff;
    border: 1px solid #ffb3f7;
  }
`;

export const IconImage = styled.div`
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
`;

export const IconTitle = styled.div`
  font-size: 13px;
  letter-spacing: -0.02em;
  margin-top: 12px;
  color: #333;
`;
