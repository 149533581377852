import styled from 'styled-components';
import { FlexRow } from '@components/wrapper';

export const Wrapper = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  z-index: 11;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
`;

export const Container = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  width: 900px;
`;

export const CategoryButton = styled.div`
  cursor: pointer;
`;

export const CommonLi = styled.div<{ isActive?: boolean }>`
  position: relative;
  height: 100%;

  ${(props) => props.isActive && 'border-bottom: 2px #000000 solid;'}
`;
