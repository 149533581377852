import React from 'react';
import styled, { useTheme } from 'styled-components';

const Styled = {
  UserArrowContainer: styled.div<{ isOpen: boolean }>`
    position: relative;
    transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
  `,
};

export default function GnbUserArrow({ className = '', isOpen = false }) {
  const theme = useTheme();

  return (
    <Styled.UserArrowContainer isOpen={isOpen} className={`${className}`}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.17574 4.67574C3.41005 4.44142 3.78995 4.44142 4.02426 4.67574L6.1 6.75147L8.17574 4.67574C8.41005 4.44142 8.78995 4.44142 9.02426 4.67574C9.25858 4.91005 9.25858 5.28995 9.02426 5.52426L6.52426 8.02426C6.28995 8.25858 5.91005 8.25858 5.67574 8.02426L3.17574 5.52426C2.94142 5.28995 2.94142 4.91005 3.17574 4.67574Z"
          fill={theme.colors.icon}
        />
      </svg>
    </Styled.UserArrowContainer>
  );
}
