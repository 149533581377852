import React from 'react';
import { CustomIcon } from '../Icon';
import { CustomText } from '../Text';
import { ICustomTextProps } from '../Text/CustomText';
import * as Styled from './styled';

type Props = {
  checked?: boolean;
  className?: string;
  name?: string;
  label?: string;
  labelStyle?: ICustomTextProps;
  onChange?: (arg: boolean) => void;
  disabled?: boolean;
  valid?: string;
};

/**
 * 체크박스 컴포넌트입니다.
 */
export default function LabelCheckbox({
  name,
  checked,
  className,
  onChange,
  label,
  labelStyle,
  disabled = false,
  valid = null,
}: Props) {
  // checkbox 변경 이벤트 처리기 메소드
  const handleCheckboxChange = (e) => {
    // 비활성화 된 checkbox를 클릭했을 경우
    if (disabled) return;
    onChange && onChange(e.target.checked);
  };

  const imageUrl = disabled
    ? 'vivid-disabled-checkbox.png'
    : checked
      ? 'vivid-checked-checkbox.png'
      : 'vivid-unchecked-checkbox.png';

  const handleLabelClick = (e) => {
    e.preventDefault();
  };

  return (
    <Styled.FlexLabel className={className} onClick={!onChange ? handleLabelClick : null}>
      <Styled.DisplayCheckbox disabled={disabled}>
        <Styled.Checkbox name={name} checked={checked} onChange={handleCheckboxChange} data-valid={valid} />
        <CustomIcon src={`/icons/checkbox/${imageUrl}`} alt="checkbox" title="checkbox" width="16px" height="16px" />
      </Styled.DisplayCheckbox>
      {label && (
        <CustomText marginLeft={10} {...labelStyle}>
          {label}
        </CustomText>
      )}
    </Styled.FlexLabel>
  );
}
