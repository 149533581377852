import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import color from './color';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export const breakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1296,
    },
  };

export default function ThemeMui({ children }: Props) {
  const theme = createTheme({
    palette: {
      // [todo]: color.json의 경우 플린님이 만둘어두신 components/ads 디렉토리로 이동해야 함.
      ...color,

      // @ts-ignore
      primary: {
        main: '#f300ba',
      },
      // @ts-ignore
      secondary: {
        main: color.violet['600'],
      },


    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
            marginRight: '10px',
            '& .MuiSvgIcon-root': {
              height: 1,
              width: 1,
            },
          },
        },
      },
    },
    breakpoints,
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
