import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function RightShadowArrowIcon({ color = '#333', weight = 'normal', className, size, onClick }: IconProps) {
  // const strokeWidth = weight === 'thin' ? 1.5 : 2;

  return (
    <Styled.IconWrapper className={className} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 37.9999C19.5327 38.0008 19.0798 37.8381 18.72 37.5399C18.311 37.2008 18.0537 36.7129 18.0049 36.1838C17.9561 35.6548 18.1199 35.1281 18.46 34.7199L27.42 23.9999L18.78 13.2599C18.4444 12.8467 18.2874 12.3168 18.3438 11.7875C18.4001 11.2582 18.665 10.7732 19.08 10.4399C19.4983 10.0718 20.0512 9.89494 20.6055 9.95189C21.1598 10.0088 21.6652 10.2944 22 10.7399L31.66 22.7399C32.2666 23.4779 32.2666 24.5419 31.66 25.2799L21.66 37.2799C21.2531 37.7708 20.6365 38.0382 20 37.9999Z"
          fill={color}
        />
        <defs>
          <filter id="filter0_d_355_2164" x="9.99646" y="5.94141" width="30.1185" height="44.0621" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_2164" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_2164" result="shape" />
          </filter>
        </defs>
      </svg>
    </Styled.IconWrapper>
  );
}
