import React from 'react';

export default function ViewIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.31096 6.05136C1.30278 6.01966 1.3007 6.00351 1.30018 5.99956C1.3007 5.99512 1.30274 5.97822 1.31081 5.94552C1.32062 5.90572 1.33663 5.85328 1.36004 5.78893C1.4068 5.66042 1.47918 5.49521 1.57831 5.30575C1.77656 4.92688 2.07557 4.46298 2.4743 4.01403C3.27317 3.11457 4.44791 2.3 6 2.3C7.55209 2.3 8.72683 3.11457 9.5257 4.01403C9.92443 4.46298 10.2234 4.92688 10.4217 5.30575C10.5208 5.49521 10.5932 5.66042 10.64 5.78893C10.6634 5.85328 10.6794 5.90572 10.6892 5.94552C10.6973 5.97821 10.6993 5.99511 10.6998 5.99956C10.6993 6.00351 10.6972 6.01967 10.689 6.05136C10.6789 6.0908 10.6622 6.14301 10.6379 6.20734C10.5892 6.33578 10.514 6.50107 10.4115 6.69079C10.2067 7.07015 9.89955 7.53481 9.495 7.98456C8.68331 8.88692 7.50808 9.7 6 9.7C4.49192 9.7 3.31669 8.88692 2.505 7.98456C2.10045 7.53481 1.79331 7.07015 1.58846 6.69079C1.48601 6.50107 1.41083 6.33578 1.36215 6.20734C1.33777 6.14301 1.32113 6.0908 1.31096 6.05136Z"
        fill="#DEDFEC"
        stroke="#D5D8EA"
        strokeWidth="0.6"
      />
      <path
        d="M7.8002 5.8C7.8002 6.79411 6.99431 7.6 6.0002 7.6C5.00608 7.6 4.2002 6.79411 4.2002 5.8C4.2002 4.80589 5.00608 4 6.0002 4C6.99431 4 7.8002 4.80589 7.8002 5.8Z"
        fill="#D5D8EA"
        stroke="white"
      />
    </svg>
  );
}
