import React from 'react';
import { useTheme } from 'styled-components';

export default function GnbScrap({ className = '' }) {
  const theme = useTheme();

  return (
    <div className={`relative ${className}`}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6 3.66666C4.6 3.16961 5.00294 2.76666 5.5 2.76666H16.5C16.9971 2.76666 17.4 3.16961 17.4 3.66666V18.3333C17.4 18.6589 17.2242 18.9591 16.9402 19.1183C16.6563 19.2776 16.3085 19.271 16.0307 19.1013L11 16.027L5.9693 19.1013C5.69151 19.271 5.3437 19.2776 5.05976 19.1183C4.77581 18.9591 4.6 18.6589 4.6 18.3333V3.66666ZM6.4 4.56666V16.7286L10.5307 14.2043C10.8188 14.0282 11.1812 14.0282 11.4693 14.2043L15.6 16.7286V4.56666H6.4Z"
          fill={theme.colors.icon}
        />
      </svg>
    </div>
  );
}
