import styled from 'styled-components';

export const Wrapper = styled.div<{ isShow: boolean }>`
  ${(props) => !props.isShow && 'visibility: hidden;'}
  position: absolute;
  right: 59px;
  margin-left: 8px;
`;

export const TimerText = styled.div<{ color?: string }>`
  ${(props) => `color: ${props.color || 'white'};`}
  font-weight: bold;
  font-size: 12px;
`;
