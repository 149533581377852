import React from 'react';

export const GoogleTag: React.FC = () => {
  return (
    <>
      <meta name="google-site-verification" content="dBxrSmtJXROTRQglgupaRE4KpOIt2LD1ORrarS0j72s" />
      {/* eslint-disable @next/next/next-script-for-ga */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PRHFBVT');
        `,
        }}
      />
    </>
  );
};

export const GoogleAnalyticsTag: React.FC = () => {
  const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA4_ID;
  if (!GA_MEASUREMENT_ID) return null;
  return (
    <>
      <script src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} async />
      <script
        id="gtag-init"
        async
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GA_MEASUREMENT_ID}');  
      `,
        }}
      />
    </>
  );
};

export const FacebookTag: React.FC = () => {
  return (
    <>
      <meta name="facebook-domain-verification" content="yjtsd0qza49deyo6q2wdli5cgijnv8" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '750381895332986');
                        fbq('track', 'PageView');
                    `,
        }}
      />
    </>
  );
};

export const NoScriptTag: React.FC = () => {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PRHFBVT"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=750381895332986&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
    </>
  );
};

export const NaverCommonScriptTag: React.FC = () => {
  return (
    <>
      {/* eslint-disable @next/next/no-sync-scripts */}
      <script type="text/javascript" src="//wcs.naver.net/wcslog.js" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          if (!wcs_add) var wcs_add={};
          wcs_add["wa"] = "s_2cb598f83c2f";
          if (!_nasa) var _nasa={};
          if(window.wcs){
            wcs.inflow();
            wcs_do(_nasa);
          }
        `,
        }}
      />
    </>
  );
};

export const NaverAnalyticsScriptTag: React.FC = () => {
  /* eslint-disable @next/next/no-sync-scripts */
  return <script type="text/javascript" src="//wcs.naver.net/wcslog.js" />;
};

export const TwitterPixelTag: React.FC = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','o7rhe');
        `,
      }}
    />
  );
};
