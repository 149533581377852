import { getKoreaDate } from '@util/time';
import axios from 'axios';

// throttle refresh request. at most once a second.
let refreshLock = false;

export function serializeAuthToken(accessToken: string, refreshToken: string): string {
  return `${accessToken}//${refreshToken}`;
}

export function deserializeAuthToken(authToken: string): { accessToken: string; refreshToken: string } {
  const [accessToken, refreshToken] = (authToken || '//').split('//');
  return {
    accessToken: accessToken || '',
    refreshToken: refreshToken || '',
  };
}

export function requestRefresh(tokenStorageHook, languageCode: string, callback?: (accessToken: string) => void) {
  const { authToken, setAuthToken, removeAuthToken } = tokenStorageHook;

  if (refreshLock) {
    // case when lock is not released for some reason.
    setTimeout(() => {
      refreshLock = false;
    }, 1000);

    // wait for refresh, before retry (Apollo Client)
    return new Promise((resolve) => {
      setTimeout(() => resolve(''), 3000);
    });
  }
  refreshLock = true;
  setTimeout(() => {
    refreshLock = false;
  }, 1000);

  // const tokenLockName = 'acon-auth-token-refresh-lock';
  // const testLock = localStorage.getItem(tokenLockName);
  // const currentTime = getKoreaDate().getTime();

  // if (testLock && currentTime - Number(testLock) <= 3000) {
  //   // wait for refresh, before retry (Apollo Client).
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(''), 3000);
  //   });
  // }

  // localStorage.setItem(tokenLockName, currentTime.toString());

  const { accessToken, refreshToken } = deserializeAuthToken(authToken);

  return axios
    .post(process.env.NEXT_PUBLIC_AUTH_V2_URL + '/acon/refresh', JSON.stringify({ access_token: accessToken, refresh_token: refreshToken, language: languageCode }), {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    })
    .then(function (response) {
      setAuthToken(serializeAuthToken(response.data.access_token, response.data.refresh_token));
      callback(serializeAuthToken(response.data.access_token, response.data.refresh_token));
    })
    .catch(function (error) {
      removeAuthToken();
      location.href = '/users/login'; // [TODO] : can use nextjs router outside component?
      throw error; // error를 던져줘서, apollo onError 에서 같은 쿼리를 두번 보내지 않도록 함.
    });
}
