import React from 'react';
import { colors } from '@public/theme';
import { CustomIcon } from '../../Icon';
import { CustomText } from '../../Text';

export const DatepickerInput = React.forwardRef<HTMLInputElement, React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>((props, ref) => {
  return (
    <div ref={ref} {...props}>
      <div>
        {props.value || !props.placeholder ? (
          <CustomText weight={500}>{props.value ? props.value : '-'}</CustomText>
        ) : (
          <CustomText color={colors.gray.c5}>{props.placeholder}</CustomText>
        )}
      </div>
      <CustomIcon src={'/icons/datepicker/calendar-icon.svg'} alt="calendar" title="calendar" width="24px" height="24px" />
    </div>
  );
});
DatepickerInput.displayName = 'DatepickerInput';
