import { createGlobalStyle } from 'styled-components';
import { LANGUAGE_CODE } from '../constants';

const GlobalStyle = createGlobalStyle<{ lang?: string }>`
  $azstorage: 'https://storage.acon3d.com';
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  body,
  body * {
    margin: 0;
    padding: 0;
    font-family: ${(props) => {
    // eslint-disable-next-line quotes
    if (props.lang === LANGUAGE_CODE.CN) return "arial,'pingfang sc','microsoft yahei',sans-serif";
    return 'Pretendard';
  }}
  }

  a {
    color: #252525;
    text-decoration: none;
  }

  .imp-frame-html5_inicis {
    position: fixed !important;
    z-index: 10000;
  }
  .noscroll {
    overflow: hidden;
  }
`;

export default GlobalStyle;
