import styled, { keyframes } from 'styled-components';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const SkeletonParent = styled.div`
  width: 100%;
  height: 100%;
`;

export const SkeletonParentTitle = styled.div`
  width: 80px;
  height: 24px;
  border-radius: 5px;
  background-image: linear-gradient(lightgray 30px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  margin-bottom: 20px;
`;

export const SkeletonParentItem = styled.div`
  height: 15px;
  border-radius: 5px;
  background-image: linear-gradient(lightgray 30px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  margin-bottom: 15px;

  ${(props) => `width: ${props['data-width']}px;`}
`;
