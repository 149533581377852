import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

export default function UpArrowIcon({ color = '#333', weight = 'normal', className, size }: IconProps) {
  const strokeWidth = weight === 'thin' ? 1.5 : 2;

  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 15.5L12 8.5L19 15.5" stroke={color} strokeWidth={strokeWidth} />
      </svg>
    </Styled.IconWrapper>
  );
}
