// @mui
import { useTheme } from '@mui/material/styles';
import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

/* eslint-disable @typescript-eslint/no-empty-function */
export const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

export interface IDeviceState {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
}
const INITIAL_DEVICE_STATE: IDeviceState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  width: typeof window === 'undefined' ? 0 : window.innerWidth,
  height: typeof window === 'undefined' ? 0 : window.innerHeight,
};

export default function useResponsiveDevice() {
  const theme = useTheme();
  const [result, setResult] = useState<IDeviceState>(INITIAL_DEVICE_STATE);
  const sizes = theme.breakpoints.values;

  useBrowserLayoutEffect(() => {
    const resize = () => {
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;
      const isMobile = innerWidth <= sizes.sm;
      const isTablet = innerWidth > sizes.sm && innerWidth <= sizes.md;
      const isDesktop = innerWidth > sizes.md;
      setResult({ isMobile, isTablet, isDesktop, width: innerWidth, height: innerHeight });
    };

    resize();

    const resizeDebounced = debounce(resize, 100);

    window.addEventListener('resize', resizeDebounced);
    return () => {
      window.removeEventListener('resize', resizeDebounced);
    };
  }, []);

  return result;
}
