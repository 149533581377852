import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { CustomIcon } from '@components/ui/Icon';
import { CustomLink, CustomText } from '@components/ui';
import { LANGUAGE_CODE } from 'src/constants';
import { PATH_BRAND } from 'src/routes/paths';
import PriceText from '@components/PriceText';
import { NewCloseIcon } from '../../../src/assets/icons/NewCloseIcon';
import { NewCheckIcon } from '../../../src/assets/icons/NewCheckIcon';
import { DefaultBadge, ExtensionBadge, RankBadge } from '../CardGrid/Badge';
import ViewCount from '../CardGrid/count/ViewCount';
import { AddCartButton } from '../CardGrid/cart';
import { ProductCardType, TActionName } from '../CardGrid/type';
import {
  AconOnly,
  BulletPoint,
  CardBadgeContainer,
  CardBody,
  CardBorder,
  CardCheckBox,
  CardContainer,
  CardContents,
  CardCount,
  CardCreater,
  CardExtension,
  CardFooter,
  CardHeader,
  CardMiddle,
  CardPercentText,
  CardPriceContainer,
  CardRealPriceText,
  CardReview,
  CardReviewCount,
  CardTitle,
  CardTitleText,
  DeleteScrap,
} from './styled';
import { CardImg } from './cardimg';

const PromotionTimerShower = dynamic(() => import('../CardGrid/Timer/PromotionTimerShower'), {
  ssr: false,
});

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function Card({
  languageCode,
  discountPercentage,
  ranking,
  isDisplayCartButton,
  isDisplayOnScrap,
  productViewUrl,
  wishSno,
  goodsNo,
  brandCd,
  isGoods,
  imageUrl,
  subImageUrl,
  brandName,
  title,
  price,
  reviewCount,
  viewCount,
  extensions,
  badgeName,
  isCart,
  isScrapEdit,
  selectedGoods,
  onChangeCardCheckBox,
  onRemoveScrap,
  onAddCart,
  isPromotionTimer = false,
  promotionEndDate,
  isAconOnly = false,
  isPromotion = false,
  onSale = true,
  isDisplayReviewCount = true,
  isDisplayDiscountText = true,
  isDisableThumbnail = false,
  isBorderRound = false,
  className,
  onClick,
  onDisplay,
  isExclusive,
  ...rest
}: ProductCardType) {
  // 번역도구
  const { t, i18n } = useTranslation(['product']);
  const theme = useTheme();
  const [isMouseOver, setIsMouseOver] = useState(false);

  // 스크랩 페이지(mypage/clip-book)에서 편집 버튼을 누르면 활성화되는 삭제 버튼의 onClick event handler
  const handleDeleteScrapClick = () => {
    onRemoveScrap(goodsNo);
  };

  // 카드 체크박스 클릭 이벤트
  const handleScrapEditCheckbox = (goodsNo: string) => {
    if (isScrapEdit) onChangeCardCheckBox(!checked, goodsNo);
  };

  // 선택된 항목 찾기
  const findGoods = (goodsNo: string) => {
    return selectedGoods?.find((x) => x === goodsNo);
  };

  // mouse over 이벤트
  const handleMouseOver = () => {
    setIsMouseOver(true);
  };
  // mouse leave 이벤트
  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const handleCardClick = (actionName: TActionName) => {
    onClick && onClick({ actionName, title, goodsNo });
  };

  // 체크됨 여부
  const checked = Boolean(findGoods(goodsNo));

  const created = rest.created;
  const isNewProduct = dayjs(created).isAfter(dayjs().subtract(30, 'days'));

  return (
    <CardContainer
      onClick={() => {
        handleScrapEditCheckbox(goodsNo);
      }}
      isDisplayOnScrap={isDisplayOnScrap}
      isScrapEdit={isScrapEdit}
      isChecked={checked}
      isShowContainer={isGoods}
      // onMouseOver={handleMouseOver}
      // onMouseLeave={handleMouseLeave}
      isDisableThumbnail={isDisableThumbnail}
      isBorderRound={isBorderRound}
      className={`mb-12 ${className}`}
      {...rest}
    >
      {isScrapEdit && (isMouseOver || checked) && <CardBorder />}
      <CardHeader isDisabled={isScrapEdit}>
        {isDisplayOnScrap &&
          (!isScrapEdit ? (
            <DeleteScrap onClick={handleDeleteScrapClick}>
              <NewCloseIcon color={'white'} />
            </DeleteScrap>
          ) : (
            <CardCheckBox isChecked={checked}>{checked && <NewCheckIcon />}</CardCheckBox>
          ))}
        <CardImg
          title={title}
          href={productViewUrl}
          mainImageURL={imageUrl}
          subImageURL={subImageUrl}
          goodsNo={goodsNo}
          wishSno={wishSno}
          isDisplayOnScrap={isDisplayOnScrap}
          isScrapEdit={isScrapEdit}
          onSale={onSale}
          badgeNames={[...(isNewProduct ? ['NEW'] : []), ...(badgeName ? [badgeName] : [])]}
          onClick={handleCardClick}
        />
        <CardBadgeContainer>
          {ranking ? (
            <RankBadge>{ranking}</RankBadge>
          ) : (
            <>
              {isNewProduct && (
                <DefaultBadge size={'fit'} background={theme.palette.green[600]} border={theme.palette.green[700]}>
                  NEW
                </DefaultBadge>
              )}
              {isExclusive && (
                <DefaultBadge size={'fit'} background={theme.palette.violet[600]} border={theme.palette.violet[800]}>
                  ACON ONLY
                </DefaultBadge>
              )}
              {badgeName && (
                <DefaultBadge size={'fit'} background={'#DD43EB'} border={'#D200BD'}>
                  {badgeName}
                </DefaultBadge>
              )}
            </>
          )}
        </CardBadgeContainer>
        <PromotionTimerShower isPromotionTimer={isPromotionTimer} onSale={onSale} promotionEndDate={promotionEndDate} />
      </CardHeader>
      {isGoods && (
        <CardContents isDisabled={isScrapEdit}>
          <CardBody className="cardBody">
            <CardMiddle>
              <CardCreater
                onClick={() => {
                  handleCardClick('brand');
                }}
              >
                <CustomLink href={PATH_BRAND.view(brandCd)} newWindow>
                  {brandName}
                </CustomLink>
              </CardCreater>
              <CardCount>
                {isDisplayReviewCount && (
                  <CardReview>
                    <CustomIcon src="/icons/goods/comments-icon.svg" alt="commentsIcon" title="commentsIcon" width="12px" height="12px" />
                    <CardReviewCount>{reviewCount.toLocaleString('ko-KR')}</CardReviewCount>
                  </CardReview>
                )}
                <ViewCount count={viewCount} />
              </CardCount>
            </CardMiddle>
            <CardTitle
              onClick={() => {
                handleCardClick('title');
              }}
            >
              <CustomLink href={productViewUrl} newWindow>
                <CardTitleText lang={i18n.language}>{title}</CardTitleText>
              </CustomLink>
            </CardTitle>
            <CardPriceContainer>
              {price <= 0 ? (
                <CardRealPriceText>FREE</CardRealPriceText>
              ) : (
                <>
                  {discountPercentage > 0 && <CardPercentText data-active={isPromotion}>{discountPercentage}%</CardPercentText>}
                  <div className={'mr-2.5'}>
                    <PriceText
                      amount={price}
                      type={'display'}
                      style={{ alignItems: 'baseline' }}
                      commonProps={{
                        size: 16,
                        weight: 500,
                        lineHeight: '100%',
                        color: '#000',
                      }}
                      currencyUnitProps={{
                        size: i18n.language === LANGUAGE_CODE.KO ? 12 : 16,
                        weight: i18n.language === LANGUAGE_CODE.KO ? 400 : 500,
                        color: '#000',
                      }}
                      currencyUnitStyle={{
                        marginLeft: i18n.language === LANGUAGE_CODE.KO ? 2 : 0,
                      }}
                    />
                  </div>
                  {
                    // 고도몰 혜택이 적용되어 있을 경우 "추가 할인" 문구 표시
                    isPromotion && isDisplayDiscountText && (
                      <div className={'flex items-baseline'}>
                        <BulletPoint color={'#B16EBC'}>•</BulletPoint>
                        <CustomText lineHeight={'100%'} weight={400} size={12} color={'#B16EBC'}>
                          {t('product:ExtraDiscount')}
                        </CustomText>
                      </div>
                    )
                  }
                </>
              )}
            </CardPriceContainer>
          </CardBody>
          <CardFooter>
            <CardExtension>
              {(extensions ?? []).map((extension, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    handleCardClick('extension');
                  }}
                >
                  <CustomLink href={productViewUrl}>
                    <ExtensionBadge name={extension.replace('.', '')} />
                  </CustomLink>
                </div>
              ))}
            </CardExtension>
            {
              //카트 담기 버튼
              isDisplayCartButton && <AddCartButton isScrapEdit={isScrapEdit} onSale={onSale} goodsNo={goodsNo} title={title} price={price} onAddCart={onAddCart} />
            }
          </CardFooter>
        </CardContents>
      )}
    </CardContainer>
  );
}
