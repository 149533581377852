import styled from 'styled-components';

export const Label = styled.label`
  display: inline-block;
`;

export const FlexLabel = styled.label`
  display: flex;
`;
export const DisplayCheckbox = styled.label<{ disabled: boolean }>`
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;
