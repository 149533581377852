export default {
  white: '#FFFFFF',
  black: '#000000',
  text: {
    primary: '#313135',
    disabled: '#E7E7E8',
  },
  gray: {
    50: '#F5F5F6',
    100: '#E7E7E8',
    200: '#D2D2D3',
    300: '#BCBCBE',
    400: '#A2A2A4',
    500: '#88888A',
    600: '#6D6D70',
    700: '#4C4C50',
    800: '#313135',
    900: '#18181B',
  },
  dim: {
    100: '#ffffff',
    200: '#ECECED',
    300: '#E0E0E1',
    400: '#DDDDDE',
    500: '#BDBDBE',
    600: '#AEAEB0',
    700: '#6F6F72',
    800: '#464649',
    900: '#333333',
  },
  violet: {
    100: '#E6E6FF',
    200: '#CECEFF',
    300: '#AEAEFF',
    400: '#8E8EFF',
    500: '#6A6AFF',
    600: '#4E4EFF',
    700: '#3E3ECD',
    800: '#2F2F9B',
    900: '#2F2F9B',
  },
  yellow: {
    100: '#FBFFE8',
    200: '#F5FFC9',
    300: '#EBFF93',
    400: '#E0FF55',
    500: '#D3F050',
    600: '#C0DB49',
    700: '#A1B73E',
    800: '#72822C',
    900: '#434C1B',
  },
  red: {
    100: '#FFEBE9',
    200: '#FECAC3',
    300: '#FDA296',
    400: '#FD8170',
    500: '#FC6D5A',
    600: '#DF6050',
    700: '#C25446',
    800: '#974137',
    900: '#6C2F28',
  },
  orange: {
    100: '#FFF6E8',
    200: '#FFE6C1',
    300: '#FFD493',
    400: '#FFC774',
    500: '#FFBB55',
    600: '#E2A64B',
    700: '#C59042',
    800: '#997034',
    900: '#6D5025',
  },
  green: {
    100: '#E2FDF1',
    200: '#BEFADF',
    300: '#7DF4BE',
    400: '#33EE99',
    500: '#30E090',
    600: '#2CCD84',
    700: '#25AB6F',
    800: '#1A7950',
    900: '#0F4730',
  },
  skyblue: {
    100: '#E7FAFF',
    200: '#C8F4FF',
    300: '#93E9FF',
    400: '#55DDFF',
    500: '#50D0F1',
    600: '#49BEDC',
    700: '#3D9FB9',
    800: '#2B7084',
    900: '#194250',
  },
  state: {
    info: '#00B8D9',
    success: '#36B37E',
    warning: '#FFAB00',
    error: '#FF5630',
  },
  background: {
    1: '#FAFAFC',
    2: '#F8F8FB',
    3: '#F7F7FC',
    4: '#F5F5FA',
    5: '#F4F4F9',
  },
} as const;
