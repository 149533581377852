declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

type ImpressionCardGroupType = {
  index: number;
  sno: string;
  goodsNo: string;
  title: string;
  brandCd: string;
  price: number;
};

export const event = (data) => {
  if (typeof window === 'undefined') return;
  if (!window.dataLayer) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(data);
};

export const impressionsCardGroupEvent = (productCards: ImpressionCardGroupType[]) => {
  event({
    ecommerce: {
      impressions: productCards.map((productCard, idx) => {
        return {
          id: productCard.goodsNo,
          name: productCard.title,
          list_name: `Card Group ${productCard.sno}: ${productCard.title}`,
          brand: productCard.brandCd,
          list_position: idx,
          price: productCard.price,
        };
      }),
    },
  });
};
