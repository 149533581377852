import React, { useState, ComponentProps } from 'react';
import Select, { components, ActionMeta } from 'react-select';
import { Validator } from '@lib/form';
import { CustomIcon } from '@components/ui/Icon';
import { InputContainer, InputLabel, InputInfo, Validation, InputState } from './ValidationInput';

interface Props extends ComponentProps<typeof Select> {
  label?: string;
  info?: string;
  style?: React.CSSProperties;
  state?: InputState;
  valueName?: string;
  validator?: Validator;
}

const { DropdownIndicator } = components;

const IconDropdownIndicator = (props) => {
  return (
    <DropdownIndicator {...props}>
      <CustomIcon src={'/icons/combo/under-arrow.png'} alt="show" title="show" width="16px" height="16px" priority={true} />
    </DropdownIndicator>
  );
};

const IndicatorSeparator = () => {
  return <span />;
};

const SearchSelect = (props: Props) => {
  const _state: InputState = {
    value: useState(),
    valid: useState(true),
    info: useState(''),
  };

  const { label, info, state = _state, validator, style, styles, onChange, ...rest } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [value, setValue] = state.value;
  const [valid, setValid] = state.valid;
  const [message, setMessage] = state.info;

  const _onChange = async (e: any, action: ActionMeta<unknown>) => {
    setValue(e);

    if (validator) {
      const r = await validator(e);
      setValid(r.valid);
      setMessage(r.info);
    }

    if (onChange) onChange(e, action);
  };

  const customSelectStyles = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    control: (provided, state) => ({
      ...provided,
      height: '39px',
      borderRadius: '3px',
      borderColor: valid ? '#cdcdcd' : '#f300ba',
    }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '12px',
    }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 16px',
    }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: '12px',
    }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
    }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    input: (provided, state) => ({
      ...provided,
      fontSize: '12px',
    }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: isMenuOpen && 'rotate(180deg)',
    }),
    ...styles,
  };

  const color: string = valid === false ? '#f300ba' : '#3a3e94';

  return (
    <InputContainer
      style={style}
      {...(props?.valueName && {
        'data-mixpanel-action': 'click',
        'data-mixpanel-evt': 'Button Click',
        'data-mixpanel-action_name': props.valueName,
        'data-mixpanel-page_name': 'signup',
        'data-mixpanel-collect-once': 'true',
      })}
    >
      {label && <InputLabel>{label}</InputLabel>}
      {info && <InputInfo>{info}</InputInfo>}
      <Select
        onMenuOpen={() => {
          setIsMenuOpen(true);
        }}
        onMenuClose={() => {
          setIsMenuOpen(false);
        }}
        components={{ IndicatorSeparator, DropdownIndicator: IconDropdownIndicator }}
        styles={customSelectStyles}
        value={value}
        onChange={_onChange}
        {...rest}
      />
      {message && <Validation color={color}>{message}</Validation>}
    </InputContainer>
  );
};

export default SearchSelect;
