import React from 'react';

export default function GnbScrap() {
  return (
    <svg width="95" height="22" viewBox="0 0 95 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_659)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6222 0.5H4.64444L0 20.7666H6.33333L6.75555 18.2333H13.5111L13.9333 20.7667L20.2667 20.7666L15.6222 0.5ZM11.5407 6.41111H8.72592L7.52962 13.5889H12.737L11.5407 6.41111Z"
          fill="black"
        />
        <path
          d="M20.2667 10.6333C20.2667 5.03684 24.8035 0.5 30.4 0.5H35.4667V7.25556L30.7796 6.61642C28.3454 6.28448 26.1778 8.17661 26.1778 10.6333C26.1778 13.0901 28.3454 14.9822 30.7796 14.6503L35.4667 14.0111V20.7666H30.4C24.8035 20.7666 20.2667 16.2298 20.2667 10.6333Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.8667 0.5C41.5034 0.5 37.1556 4.84781 37.1556 10.2111V11.0556C37.1556 16.4189 41.5034 20.7667 46.8667 20.7667C52.23 20.7667 56.5778 16.4189 56.5778 11.0556V10.2111C56.5778 4.84781 52.23 0.5 46.8667 0.5ZM46.8667 6.41111C44.768 6.41111 43.0667 8.11243 43.0667 10.2111V11.0556C43.0667 13.1542 44.768 14.8556 46.8667 14.8556C48.9654 14.8556 50.6667 13.1542 50.6667 11.0556V10.2111C50.6667 8.11243 48.9654 6.41111 46.8667 6.41111Z"
          fill="black"
        />
        <path d="M58.2667 20.7667H64.6L63.9179 9.85385L70 19.9207V10.8356L63.7556 0.5H58.2667V20.7667Z" fill="black" />
        <path d="M70.3042 10.7H76V0.5H69.6667L70.3042 10.7Z" fill="black" />
        <path
          d="M72.0891 20.8H75.3273C77.1594 20.8 78.0578 19.8664 78.0578 18.6203C78.0578 17.4094 77.1984 16.6984 76.3469 16.6555V16.5773C77.1281 16.3938 77.7453 15.8469 77.7453 14.8742C77.7453 13.6828 76.8859 12.8 75.1516 12.8H72.0891V20.8ZM73.5383 19.5891V17.2453H75.1359C76.0305 17.2453 76.5852 17.7922 76.5852 18.507C76.5852 19.1437 76.1477 19.5891 75.0969 19.5891H73.5383ZM73.5383 16.2023V13.9953H75.0031C75.8547 13.9953 76.2961 14.4445 76.2961 15.0617C76.2961 15.7648 75.7258 16.2023 74.9719 16.2023H73.5383Z"
          fill="black"
        />
        <path
          d="M81.6923 20.9172C83.0907 20.9172 84.0517 20.2336 84.3017 19.1906L82.9814 19.0422C82.79 19.55 82.3212 19.8156 81.7118 19.8156C80.7978 19.8156 80.1923 19.2141 80.1806 18.1867H84.3603V17.7531C84.3603 15.6477 83.0947 14.7219 81.6181 14.7219C79.8993 14.7219 78.7782 15.9836 78.7782 17.8352C78.7782 19.718 79.8837 20.9172 81.6923 20.9172ZM80.1845 17.2336C80.2275 16.468 80.7939 15.8234 81.6376 15.8234C82.4501 15.8234 82.997 16.4172 83.0048 17.2336H80.1845Z"
          fill="black"
        />
        <path
          d="M88.3327 14.8H87.1491V13.3625H85.735V14.8H84.8834V15.8938H85.735V19.2297C85.7272 20.3586 86.5475 20.9133 87.61 20.882C88.0123 20.8703 88.2897 20.7922 88.442 20.7414L88.2038 19.6359C88.1256 19.6555 87.9655 19.6906 87.7897 19.6906C87.4342 19.6906 87.1491 19.5656 87.1491 18.9953V15.8938H88.3327V14.8Z"
          fill="black"
        />
        <path
          d="M91.0218 20.9211C91.9632 20.9211 92.5257 20.4797 92.7836 19.9758H92.8304V20.8H94.1898V16.7844C94.1898 15.1984 92.8968 14.7219 91.7523 14.7219C90.4906 14.7219 89.5218 15.2844 89.2093 16.3781L90.5296 16.5656C90.6703 16.1555 91.0687 15.8039 91.7601 15.8039C92.4164 15.8039 92.7757 16.1398 92.7757 16.7297V16.7531C92.7757 17.1594 92.35 17.1789 91.2914 17.2922C90.1273 17.4172 89.014 17.7648 89.014 19.1164C89.014 20.2961 89.8773 20.9211 91.0218 20.9211ZM91.389 19.882C90.7992 19.882 90.3773 19.6125 90.3773 19.093C90.3773 18.55 90.85 18.3234 91.4828 18.2336C91.8539 18.1828 92.5961 18.0891 92.7796 17.9406V18.6477C92.7796 19.3156 92.2406 19.882 91.389 19.882Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_659">
          <rect width="95" height="21" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
