import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

type Props = Omit<IconProps, 'weight' | 'color'>;

export default function BrandHomeIcon({ className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6103 11.8721C18.4859 8.18353 15.0564 5.5 11 5.5C6.02944 5.5 2 9.52944 2 14.5C2 19.4706 6.02944 23.5 11 23.5C11.0079 23.5 11.0158 23.5 11.0237 23.5H24.5V19.5H25.6694C26.5928 19.5 27.0228 18.3555 26.3279 17.7474L19.6586 11.9118C19.6428 11.898 19.6267 11.8848 19.6103 11.8721ZM18.7873 19.0149C19.5584 17.6878 20 16.1455 20 14.5C20 14.1587 19.981 13.8218 19.944 13.4903L25.6694 18.5L23.5 18.5V22.5H20.5V20.5C20.5 19.6715 19.8284 19 19 19C18.9278 19 18.8568 19.0051 18.7873 19.0149ZM17.5 20.725C16.8155 21.4395 16.0144 22.0413 15.1273 22.5H17.5V20.725Z"
          fill="#545454"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 15C9.69036 15 10.25 14.3284 10.25 13.5C10.25 12.6716 9.69036 12 9 12C8.30964 12 7.75 12.6716 7.75 13.5C7.75 14.3284 8.30964 15 9 15ZM13 15C13.6904 15 14.25 14.3284 14.25 13.5C14.25 12.6716 13.6904 12 13 12C12.3096 12 11.75 12.6716 11.75 13.5C11.75 14.3284 12.3096 15 13 15Z"
          fill="white"
        />
        <path
          d="M14.5002 22.5H17.5002V20.5C17.5002 19.6715 18.1718 19 19.0002 19C19.8286 19 20.5002 19.6715 20.5002 20.5V22.5H23.5002V18.5L25.6696 18.5L19.0003 12.6644L12.3311 18.5H14.5002V22.5Z"
          fill="white"
        />
      </svg>
    </Styled.IconWrapper>
  );
}
