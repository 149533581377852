import styled, { keyframes } from 'styled-components';

const skeletonAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const Skeleton = styled.div<{
  width: string;
  height: string;
  marginTop?: number;
  marginLeft?: number;
  marginBottom?: number;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: linear-gradient(lightgray ${(props) => props.height}, transparent 0);
  background-repeat: repeat-y;
  background-size: 100%;
  background-position: 0px 0px;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
`;
