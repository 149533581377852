import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  ${(props) => 'height: ' + props.bannerHeight + 'px'};
`;

export const Container = styled.div<any>`
  background-color: #fff;
  width: 100%;
  z-index: 99;
  position: fixed;
  top: ${(props) => props.top}px;
`;
