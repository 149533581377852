export const CERTIFICATION_ERROR_MESSAGE = {
  IS_ALREADY_ADULT: 'adult:certification.validation.isAlreadyAdult',
  ADULT_REJECT: 'adult:certification.validation.adultReject',
  ADULT_CANCEL: 'adult:certification.validation.adultCancel',
};

export class CertificationError extends Error {
  public loggingMessage: string;

  constructor(userMessage: string, customMessage: string) {
    super(userMessage);

    this.name = 'Payment Error';
    this.loggingMessage = customMessage;
    Object.setPrototypeOf(this, CertificationError.prototype);
  }
}
