import styled from 'styled-components';
import { EXTENSION_CODE } from 'src/constants';

export const ExtensionBadgeContainer = styled.div<{ type: EXTENSION_CODE; size?: number }>`
  padding: 4px 6px;
  border-radius: 4px;
  font-size: ${(props) => (props.size ? props.size : 12)}px;
  font-weight: 400;
  line-height: 100%;
  margin-right: 8px;

  ${(props) => {
    switch (props.type) {
      case EXTENSION_CODE.BLEND:
        return `
                    background: #FFE7FA;
                    color: #923086;
                `;
      case EXTENSION_CODE.SKP:
        return `
                    background: #E9E7FF;
                    color: #303A92;
                `;
      case EXTENSION_CODE.ETC:
        return `
                    background: #ECEDF8;
                    color: #7C7C7C;
                `;
    }
  }}
`;

export const ExtensionBadgeContainerV2 = styled.div<{ type: EXTENSION_CODE; size?: number }>`
  padding: 3px 6px;
  border-radius: 4px;
  font-size: ${(props) => (props.size ? props.size : 12)}px;
  font-weight: 400;
  line-height: 100%;
  margin-right: 6px;

  ${(props) => {
    switch (props.type) {
      case EXTENSION_CODE.BLEND:
        return `
                    background: #FFE7FA;
                    color: #923086;
                `;
      case EXTENSION_CODE.SKP:
        return `
                    background: #E9E7FF;
                    color: #303A92;
                `;
      case EXTENSION_CODE.ETC:
        return `
                    background: #ECEDF8;
                    color: #7C7C7C;
                `;
    }
  }}
`;
