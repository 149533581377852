import styled from 'styled-components';

export const MyPageContainer = styled.nav<{ width: number }>`
  width: ${(props) => `${props.width}px`};
  position: absolute;
  top: 22px;
  right: 0px;
  z-index: 12;
  text-align: center;
  padding-top: 5px;
`;

export const MyPageContents = styled.nav`
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.theme.colors.colBg};
  border: 1px solid ${(props) => props.theme.colors.listBorder};
`;

export const MyPageList = styled.ul``;

export const MyPageItemLink = styled.div<{ isNoti?: boolean }>`
  padding: 12px 0px;
  display: block;
  color: ${(props) => props.theme.colors.colText};

  &::after {
    ${(props) =>
    props.isNoti &&
      `
              content: "";
              position: absolute;
              margin-top: -6px;
              width: 5px;
              height: 5px;
              background-color: #FF00CD;
              border-radius: 50%;            
    `}
  }
`;

export const MyPageItem = styled.li`
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.colors.colActiveBg};

    ${MyPageItemLink} {
      color: ${(props) => props.theme.colors.colActiveText};
    }
  }
`;
