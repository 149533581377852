import React, { useEffect, useRef, useState } from 'react';
import { CustomText } from '@components/ui/Text';
import { colors } from '@public/theme';
import { DownArrowIcon } from 'src/assets/icons';
import * as Styled from './styled';
import { DatepickerOption } from './DatepickerOption';

export const YearSelect = ({ value, onChange, className = null, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const selectBoxRef = useRef(null);
  const checkedRef = useRef(null);
  const listRef = useRef(null);

  // 바깥 영역 클릭 시 닫음
  const handleOutSideClick = (event) => {
    const isClose = !selectBoxRef.current || !selectBoxRef.current.contains(event.target);
    if (isClose) setIsActive(false);
  };

  useEffect(() => {
    if (isActive) listRef.current.scrollTop = checkedRef.current.offsetTop - 200;
  }, [isActive]);

  // 바깥 영역을 클릭하면 드랍다운 메뉴 숨김
  useEffect(() => {
    if (isActive) {
      // document 클릭 이벤트
      document.addEventListener('click', handleOutSideClick, true);
      return () => {
        document.removeEventListener('click', handleOutSideClick, true);
      };
    }
  });

  // select 태그 클릭 이벤트 처리기 메소드
  const handleSelectClick = async () => {
    setIsActive(!isActive);
  };

  const years = (() => {
    const yearList = [];
    for (let i = 1900; i <= 2100; i += 1) yearList.push(i);

    return yearList;
  })();

  return (
    <Styled.CalendarHeader isActive={isActive} ref={selectBoxRef} onClick={handleSelectClick} className={className} {...rest}>
      <div className={'flex items-center cursor-pointer pt-1 pb-1'}>
        <CustomText color={isActive ? colors.pink.c2 : colors.black.c2} weight={700} className={'mr-2'}>
          {value}
        </CustomText>
        <DownArrowIcon size={16} color={isActive ? '#333' : '#7a7a7a'} />
      </div>
      {isActive && (
        <Styled.ListWrapper ref={listRef}>
          {years.map((x, i) => {
            const isSelected = x === value;
            return (
              <DatepickerOption
                key={i}
                value={x}
                onClick={() => {
                  onChange(x);
                }}
              >
                <Styled.ListItem isSelected={isSelected} ref={isSelected ? checkedRef : null}>
                  <CustomText color={isSelected ? colors.white : colors.black.c2}>{x}</CustomText>
                </Styled.ListItem>
              </DatepickerOption>
            );
          })}
        </Styled.ListWrapper>
      )}
    </Styled.CalendarHeader>
  );
};
