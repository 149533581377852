import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

type Props = Omit<IconProps, 'weight' | 'color'>;

export default function DownloadIcon({ className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 14.5V19.5H20V14.5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 4.5V14.25" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M16 10.25L12 14.25L8 10.25" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Styled.IconWrapper>
  );
}
