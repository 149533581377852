const delay = (ms: number = 0) => new Promise((resolve) => setTimeout(() => resolve(true), ms));

interface IThrottleWrapperOption {
  enabler?: ()=>void;
  disabler?: ()=> void;
  wait: number;
}

type THTMLWithDisableElement = HTMLInputElement| HTMLSelectElement| HTMLTextAreaElement| HTMLButtonElement| HTMLFieldSetElement| HTMLOptionElement| HTMLOptGroupElement;

/**
 * 같은 이벤트를 단위시간내에 두번 실행하지 않도록 감쌉니다.
 * 비동기 함수는 비동기로 기다리고, 동기함수는 throttle 과 같이 동작합니다.
 *
 * @param {function} callback - callback function
 * @param {object|number} option - enable/disable functions and defaultWaitTime or just defaultWaitTime
 * @param {object} option.enabler - enable function
 * @param {object} option.disabler - disable function
 * @param {number} option.wait - number wait time in ms (default 300ms)
 */
export const throttleWrapper = function(callback: any, option: IThrottleWrapperOption|number = 300) {
  if (!callback) throw new Error('No callback function was defined for throttleWrapper.');
  if (typeof callback !== 'function') throw new Error('Invalid callback function defined for throttleWrapper.');
  const beforeThrottleWrapperError = new Error('ThrottleWrapper Error stack');
  const digestedOption: IThrottleWrapperOption = typeof option === 'number' ? { wait: option } : option;
  return async (event?: Event & { currentTarget?: THTMLWithDisableElement } | any, ...args: any[]) => {
    /* eslint-disable require-atomic-updates */
    const target = event?.currentTarget || null;
    // Save target event
    const initialDisabled = target?.ariaDisabled || null;
    if (initialDisabled) return false;
    try {
      // Disable target event
      if (target) target.ariaDisabled = true;
      // disable if there is disable function
      if (digestedOption.disabler) digestedOption.disabler();

      // execute callback;
      const result = await callback(event, ...args);
      // wait more time
      if (digestedOption.wait > 0) await delay(digestedOption.wait);

      // enable if there is enable function
      if (digestedOption.enabler) digestedOption.enabler();

      // Restore target event
      if (target) target.ariaDisabled = initialDisabled;
      return result;
    } catch (e) {
      // 에러가 이벤트 롤백
      if (target) target.ariaDisabled = initialDisabled;
      if (digestedOption.enabler) digestedOption.enabler();
      e.message += '\nThe function causing this error is wrapped in throttleWrapper.';
      e.stack += `\nCaused by)\n${beforeThrottleWrapperError.stack}`;
      throw e;
    }
  };
};
