import styled from 'styled-components';
import Datepicker from 'react-datepicker';
import { colors } from '@public/theme';

// react-datepicker custom style
export const DatepickerWrapper = styled.div`
  .react-datepicker-popper {
    z-index: 999;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker {
    width: auto;
    margin-top: 6px;
    border: 1px ${colors.gray.c8} solid;
    border-radius: 4px;
    background-color: white;
    position: relative;
    padding: 24px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.06);

    .react-datepicker__day-names {
      display: flex;

      .react-datepicker__day-name {
        font-weight: 700;
      }
    }

    .react-datepicker__week {
      display: flex;

      .react-datepicker__day {
        cursor: pointer;
      }

      .react-datepicker__day--selected {
        border-radius: 50%;
        background-color: ${colors.pink.c2};
        color: white;
        font-weight: 700;
        border: none !important;
        outline: none;
      }

      .react-datepicker__day--outside-month {
        color: ${colors.gray.c12};
      }

      .react-datepicker__day--disabled {
        cursor: default;
        color: ${colors.gray.c12};
      }
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #333333;
    }
  }
`;

export const PinkDatepicker = styled(Datepicker)<{ isActive: boolean; width: number }>`
  border: 1px ${colors.gray.c14} solid;
  border-radius: 4px;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${(props) => `
    width: ${props.width}px;
    ${props.isActive ? `border: 1px ${colors.blue.c2} solid;` : ''}
  `}
`;

export const Divider = styled.div`
  width: 10px;
  height: 0px;
  border: 1px solid #dfdfdf;
`;
