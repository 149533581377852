import React from 'react';
import styled from 'styled-components';
import { CustomIcon } from '../Icon';
import * as Styled from './styled';

/**
 * 일반적인 체크박스 컴포넌트입니다.
 * @param param0
 * @returns
 */
const Label = styled.div``;
export const NormalCheckbox = ({ onClick, value, htmlFor = '', ...rest }) => {
  const imageUrl = value === 'y' ? 'normal-checked-checkbox.png' : 'normal-unchecked-checkbox.png';

  return (
    <Label>
      <Styled.Checkbox value={value} onClick={onClick} id={htmlFor} {...rest} />
      <Styled.DisplayCheckbox disabled={false} htmlFor={htmlFor}>
        <CustomIcon src={`/icons/checkbox/${imageUrl}`} alt="checkbox" title="checkbox" width="16px" height="16px" />
      </Styled.DisplayCheckbox>
    </Label>
  );
};
