import React from 'react';
import styled from 'styled-components';
import { CustomText } from '@components/ui';
import { IGnbContainerProps } from './Gnb.types';

export const GnbContainer = styled.div<IGnbContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => 'height: ' + props.height + 'px'};
  width: 100%;
  background-color: ${(props: any) => props.theme.colors.gnbBg};
  z-index: 1;
`;

export const GnbMobileSearchWrapper = styled.div<React.CSSProperties>`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  padding: 10px 16px;
  background-color: ${(props) => props.theme.colors.gnbBg};
  justify-content: space-between;
  align-items: center;
`;

export const LoginBonusBadgeContainer = styled.div<{ onClick }>`
  background-color: #313135;
  border-radius: 4px;
  padding: 6px 8px;
  position: absolute;
  top: 76px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);

  &:after {
    width: 8px;
    height: 8px;
    background: #313135;
    border-radius: 2px 0px 0px 0px;
    transform: rotate(45deg);
    right: 15.69px;
    content: '';
    top: -4px;
    position: absolute;
  }
`;

export const LoginBonusBadgeText = styled(CustomText)`
  white-space: nowrap;
`;
