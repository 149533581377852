import React from 'react';
import { CategorySkeletonContainer, SkeletonItem } from './styled';

interface props {
  short?: boolean;
}

export const CategorySkeleton: React.FC<props> = ({ short = false }) => {
  return (
    <CategorySkeletonContainer>
      {(() => {
        // 1뎁스 카테고리 아이템 나열
        const skeletonItemWidth = short
          ? [120, 120, 100, 110, 100, 100, 120, 100, 120]
          : [120, 120, 100, 110, 100, 100, 120, 100, 100, 80, 100, 80, 120, 120, 100, 110, 100, 100, 120, 100, 100, 80, 100, 80];
        return skeletonItemWidth.map((x, i) => <SkeletonItem key={i} widthSize={x} />);
      })()}
    </CategorySkeletonContainer>
  );
};
