import mixpanel from 'mixpanel-browser';
import { logEvent } from '@hooks/logger';
import { IS_PROD } from '../../constants';
import { UserInfo } from '../../provider';

const DEBUG_MIXPANEL = process.env.NEXT_PUBLIC_DEBUG_MIXPANEL;

mixpanel.init('23fa79e73c2ad93b70444f3a36fcaa0c', { debug: DEBUG_MIXPANEL, persistence: 'localStorage' });

export const event = (event_name: string, props: { [key: string]: any } = {}, isTrackedGA4Log: boolean = true) => {
  try {
    if (mixpanel && (DEBUG_MIXPANEL || IS_PROD)) mixpanel.track(event_name, props);
  } catch (e) {
    console.log(e);
  } finally {
    if (isTrackedGA4Log) logEvent(event_name, props);
  }
};

export const setSuperProperty = (props) => {
  try {
    if (mixpanel && (DEBUG_MIXPANEL || IS_PROD)) mixpanel.register(props);
  } catch (e) {
    console.log(e);
  }
};

export const setUserProfile = (userInfo: UserInfo, groupInfo?: any) => {
  try {
    if (mixpanel && (DEBUG_MIXPANEL || IS_PROD)) {
      mixpanel.identify(userInfo.memNo);
      mixpanel.people.set({
        name: userInfo.userId,
        email: userInfo.userId,
        ...groupInfo,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const increaseUserProperty = (propName: string, by: number) => {
  try {
    if (mixpanel && (DEBUG_MIXPANEL || IS_PROD)) mixpanel.people.increment(propName, by);
  } catch (e) {
    console.log(e);
  }
};

const getQueryParam = (url: string, param: string) => {
  /* eslint-disable no-empty-character-class */
  // Expects a raw URL
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  let regexS = '[?&]' + param + '=([^&#]*)',
    regex = new RegExp(regexS),
    results = regex.exec(url);

  if (results === null || (results && typeof results[1] !== 'string' && (results[1] as [])?.length)) {
    return '';
  }

  const value = decodeURIComponent(results[1]);
  return param === 'utm_term' ? value : value.replace(/\W/gi, ' ');
};

export const campaignParams = () => {
  /* eslint-disable no-plusplus */
  let campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' '),
    kw = '',
    params = {},
    first_params = {};
  let index: number;

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }

  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);

  return params;
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  event,
  setSuperProperty,
  setUserProfile,
  increaseUserProperty,
  campaignParams,
};
