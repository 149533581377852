import React, { useState, useCallback, useEffect, useRef, ReactNode } from 'react';
import styled from 'styled-components';

const Styled = {
  ScrollHost: styled.div`
    &::-webkit-scrollbar {
      display: none;
    }
    overflow: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
  `,
  ScrollHostContainer: styled.div`
    position: relative;
    height: 100%;
  `,
  ScrollBar: styled.div`
    width: 10px;
    height: calc(100% - 8px);
    right: 4px;
    top: 4px;
    position: absolute;
    border-radius: 6px;
    bottom: 0px;
    background-color: rgba(193, 193, 193, 0.5);
  `,
  ScrollThumb: styled.div`
    width: 10px;
    height: 20px;
    position: absolute;
    border-radius: 6px;
    opacity: 1;
    top: 0;
    background-color: rgba(193, 193, 193, 1);

    &:hover {
      background-color: #9e9e9e;
    }
  `,
};

const SCROLL_BOX_MIN_HEIGHT = 20;

type Props = {
  children: ReactNode;
  trigger: number;
  className?: string;
  style?: React.CSSProperties;
};

export default function CustomScrollDiv({ children, trigger, className, style }: Props) {
  const [hovering, setHovering] = useState(false);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [isDragging, setDragging] = useState(false);

  const handleMouseOver = useCallback(() => {
    if (!hovering) setHovering(true);
  }, [hovering]);

  const handleMouseOut = useCallback(() => {
    if (hovering) setHovering(false);
  }, [hovering]);

  const handleDocumentMouseUp = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [isDragging],
  );

  const handleDocumentMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();
        const scrollHostElement = scrollHostRef.current;
        const { scrollHeight, offsetHeight } = scrollHostElement;

        const deltaY = e.clientY - lastScrollThumbPosition;
        const percentage = deltaY * (scrollHeight / offsetHeight);

        setScrollThumbPosition(e.clientY);
        setScrollBoxTop(Math.min(Math.max(0, scrollBoxTop + deltaY), offsetHeight - scrollBoxHeight));
        scrollHostElement.scrollTop = Math.min(scrollHostElement.scrollTop + percentage, scrollHeight - offsetHeight);
      }
    },
    [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop],
  );

  const handleScrollThumbMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollThumbPosition(e.clientY);
    setDragging(true);
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) return;

    const scrollHostElement = scrollHostRef.current;
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    let newTop = (parseInt(scrollTop.toString(), 10) / parseInt(scrollHeight.toString(), 10)) * offsetHeight;

    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    setScrollBoxTop(newTop);
  }, []);

  const scrollHostRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!trigger) return;

    const scrollHostElement = scrollHostRef.current;
    const { clientHeight, scrollHeight } = scrollHostElement;
    const scrollThumbPercentage = clientHeight / scrollHeight;
    const scrollThumbHeight = Math.max(scrollThumbPercentage * clientHeight, SCROLL_BOX_MIN_HEIGHT);
    setScrollBoxHeight(scrollThumbHeight - 8);
    scrollHostElement.addEventListener('scroll', handleScroll, true);
    return function cleanup() {
      scrollHostElement.removeEventListener('scroll', handleScroll, true);
    };
  }, [trigger]);

  useEffect(() => {
    document.addEventListener('mousemove', handleDocumentMouseMove);
    document.addEventListener('mouseup', handleDocumentMouseUp);
    document.addEventListener('mouseleave', handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener('mousemove', handleDocumentMouseMove);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.removeEventListener('mouseleave', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  return (
    <Styled.ScrollHostContainer
      className={'scrollhost-container'}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Styled.ScrollHost ref={scrollHostRef} style={style} className={className}>
        {children}
      </Styled.ScrollHost>
      <Styled.ScrollBar style={{ opacity: hovering ? 1 : 0 }}>
        <Styled.ScrollThumb
          style={{ height: scrollBoxHeight, top: scrollBoxTop }}
          onMouseDown={handleScrollThumbMouseDown}
        />
      </Styled.ScrollBar>
    </Styled.ScrollHostContainer>
  );
}
