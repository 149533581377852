import React from 'react';
import { CustomIcon } from '../Icon';
import * as Styled from './styled';

type props = {
  checked?: boolean;
  onChange?: (arg: boolean) => void;
  disabled?: boolean;
  valid?: string;
};

/**
 * 체크박스 컴포넌트입니다.
 */
export const Checkbox: React.FC<props> = ({ checked = false, onChange, disabled = false, valid = null }) => {
  // checkbox 변경 이벤트 처리기 메소드
  const handleCheckboxChange = () => {
    // 비활성화 된 checkbox를 클릭했을 경우
    if (disabled) return;
    onChange && onChange(checked);
  };

  const imageUrl = disabled ? 'vivid-disabled-checkbox.png' : checked ? 'vivid-checked-checkbox.png' : 'vivid-unchecked-checkbox.png';

  const handleLabelClick = (e) => {
    e.preventDefault();
  };

  return (
    <Styled.Label onClick={!onChange ? handleLabelClick : null}>
      <Styled.DisplayCheckbox disabled={disabled}>
        <Styled.Checkbox checked={checked} onChange={handleCheckboxChange} data-valid={valid} />
        <CustomIcon src={`/icons/checkbox/${imageUrl}`} alt="checkbox" title="checkbox" width="16px" height="16px" />
      </Styled.DisplayCheckbox>
    </Styled.Label>
  );
};
