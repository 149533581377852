import styled from 'styled-components';
import { NoBorderButton } from '@components/ui/Button';

export const SearchList = styled.div`
  width: calc(100% - 40px);
  float: left;
  position: absolute;
  background-color: white;
  left: 20px;
  top: 32px;
  z-index: 9999;
  box-shadow: 0px 10px 18px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding-top: 27px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
`;

export const SearchFooter = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 8px;
  margin-top: 10px;
  border-top: 1px solid #e0e0e0;
`;

export const SearchFooterLink = styled.a`
  color: #f300ba;
  cursor: pointer;

  & > div {
    vertical-align: middle;
  }
`;

export const RemoveAllItemButton = styled(NoBorderButton)`
  font-size: 12px;
  line-height: 100%;
  color: #aaa;
  font-weight: 400;
  position: absolute;
  top: 25px;
  right: 16px;

  & > div {
    vertical-align: bottom;
    padding-right: 5px;
  }
`;
