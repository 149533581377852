import React from 'react';
import { useTheme } from 'styled-components';

export default function GnbHome({ className = '' }) {
  const theme = useTheme();
  const color = theme.colors.icon;

  return (
    <div className={className}>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.72363 6.16661L7.72363 1.99994L12.7236 6.16661V12.4999H2.72363V6.16661Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
        <rect x="6.22363" y="8.49994" width="3" height="4" fill={color} />
      </svg>
    </div>
  );
}
