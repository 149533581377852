import styled from 'styled-components';

const Wrapper = styled.div<{ top: number; left: number }>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;

interface Props {
  top: number;
  left: number;
}

export default function GameTabNewBadge({ top, left }: Props) {
  return (
    <Wrapper top={top} left={left}>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7.5" cy="7.5" r="7.5" fill="#FF5630" />
        <path d="M10.4834 3.72949V10.7998H9.06738L6.19629 6.64941H6.14746V10.7998H4.4873V3.72949H5.92285L8.76465 7.87988H8.82324V3.72949H10.4834Z" fill="white" />
      </svg>
    </Wrapper>
  );
}
