import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Band = styled.div<{ color: string }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  line-height: 44px;
  position: relative;
  padding-right: 5px;

  ${(props) => `
        background-color: ${props.color};
        height: 44px;
    `}

  & > a {
    width: 100%;
  }
`;

export const BandTitle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  width: calc(100% - 60px);
  ${(props) => `color: ${props.color || 'black'};`}
  font-size: 14px;
`;
