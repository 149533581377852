import React, { ReactNode } from 'react';

type props = {
  onClick?: (arg: string | number | boolean) => void;
  value: string | number | boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

export const DatepickerOption: React.FC<props> = ({ onClick, disabled, value, children, ...rest }) => {
  const handleOptionDivClick = () => {
    !disabled && onClick && onClick(value);
  };

  return (
    <div className={'flex items-center'} onClick={handleOptionDivClick} {...rest}>
      {React.Children.map<ReactNode, ReactNode>(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement, {
            disabled,
          });
        }
      })}
    </div>
  );
};
