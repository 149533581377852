import styled from 'styled-components';
import { IGridContentsContainerProps } from './GridContents.types';

export const GridContentsContainer = styled.div<IGridContentsContainerProps>`
  position: relative;
  max-width: 1444px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${(props) => props.marginBottom !== undefined && `margin-bottom: ${props.marginBottom}px;`};
  ${(props) => props.marginTop !== undefined && `margin-top: ${props.marginTop}px;`};
  ${(props) => props.gap !== undefined && `gap: ${props.gap}px;`}
`;
