import React, { useEffect, useState } from 'react';
import { enUS, ja, ko, zhCN } from 'date-fns/locale';
import { format, setMonth } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { registerLocale } from 'react-datepicker';
import { LANGUAGE_CODE } from 'src/constants';
import * as Styled from './styled';
import { DatepickerInput } from './input/DatepickerInput';
import { YearSelect } from './input/YearSelect';
import { MonthSelect } from './input/MonthSelect';

type Props = {
  width: number;
  value: Date;
  onChange: (value: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  dateFormat?: string;
  className?: string;
  getProps?: boolean;
  monthValue?: number;
  setMonthValue?: (value: number) => void;
  yearValue?: number;
  setYearValue?: (value: number) => void;
};

export default function Datepicker({ width, value, onChange, maxDate, minDate, dateFormat, className, monthValue, setMonthValue, yearValue, setYearValue, ...rest }: Props) {
  const { i18n } = useTranslation();
  // 달력 활성 여부
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    registerLocale(i18n.language, convertLang(i18n.language));
    settingDate();
  }, []);

  // date-fns 형태로 변경
  const convertLang = (language: string) => {
    switch (language) {
      case LANGUAGE_CODE.KO:
        return ko;
      case LANGUAGE_CODE.CN:
        return zhCN;
      case LANGUAGE_CODE.JP:
        return ja;
      case LANGUAGE_CODE.EN:
        return enUS;
      default:
        return enUS;
    }
  };

  const settingDate = () => {
    const targetValue = value || new Date();
    setMonthValue(targetValue.getMonth() + 1);
    setYearValue(targetValue.getFullYear());
  };

  const handleClose = () => {
    settingDate();
    setIsActive(false);
  };

  return (
    <Styled.DatepickerWrapper>
      <Styled.PinkDatepicker
        width={width}
        isActive={isActive}
        dateFormat={dateFormat || 'yy.MM.dd'}
        selected={value ? value : null}
        customInput={<DatepickerInput className={className} {...rest} />}
        calendarStartDay={1}
        onChange={onChange}
        maxDate={maxDate ? maxDate : null}
        minDate={minDate ? minDate : null}
        locale={i18n.language}
        onCalendarOpen={() => {
          setIsActive(true);
        }}
        onCalendarClose={handleClose}
        renderCustomHeader={({ changeYear, changeMonth }) => {
          const months = new Array(12).fill(null).map((x, i) => {
            return format(setMonth(new Date(), i), 'MMMM', {
              locale: convertLang(i18n.language),
            });
          });

          const handleYearChange = (year: number) => {
            setYearValue(year);
            changeYear(year);
          };

          const handleMonthChange = (month: number) => {
            setMonthValue(month + 1);
            changeMonth(month);
          };

          return (
            <div className={'flex items-center justify-center'}>
              {/* KO의 경우 년/월 순서, 글로벌 몰은 월/년 */}
              {i18n.language === LANGUAGE_CODE.KO ? (
                <>
                  {/* year */}
                  <YearSelect onChange={handleYearChange} value={yearValue} className={'ml-2'} />

                  {/* Month */}
                  <MonthSelect monthList={months} onChange={handleMonthChange} value={monthValue} />
                </>
              ) : (
                <>
                  {/* Month */}
                  <MonthSelect monthList={months} onChange={handleMonthChange} value={monthValue} className={'ml-2'} />

                  {/* year */}
                  <YearSelect onChange={handleYearChange} value={yearValue} />
                </>
              )}
            </div>
          );
        }}
      />
    </Styled.DatepickerWrapper>
  );
}
