import styled from 'styled-components';
import { FlexRowAlignCenter } from '@components/wrapper';

interface IStyleProps {
  fixed?: boolean;
  show?: boolean;
}

export const Wrapper = styled(FlexRowAlignCenter)<IStyleProps>`
  width: 100%;
  height: 35px;
  justify-content: center;
  background: #fdfad8;
  transition: opacity 1s;

  ${(props) =>
    props.fixed &&
    `
      position: fixed; 
      top: 0; 
      left: 0;    
      z-index: 99999; 
    `}

  opacity: ${(props) => (props.show ? '1' : '0')};
`;
