import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { CustomText } from '@components/ui';

export const FormCardContainer = styled.div({ width: '588px' });

export const TitleText = styled(CustomText).attrs({ size: 20, weight: 700 })({
  textAlign: 'center',
  margin: '0 auto 40px auto',
  display: 'block',
});

const Card = styled.div({
  borderRadius: '4px',
  border: '1px #e0e0e0 solid',
  boxSizing: 'border-box',
  boxShadow: '0px 2px 6px rgb(0 0 0 / 4%)',
  padding: '46px 78px',
  margin: '0px auto',
  marginBottom: '48px',
});

interface Props extends ComponentProps<typeof FormCardContainer> {
  title: string;
}

const FormCard = ({ title, children, ...rest }: Props) => {
  return (
    <FormCardContainer {...rest}>
      <TitleText>{title}</TitleText>
      <Card>{children}</Card>
    </FormCardContainer>
  );
};

export default FormCard;
