import React, { MouseEventHandler } from 'react';
import { CloseButton } from './styled';

interface props {
  onClick: MouseEventHandler;
  buttonColor: string;
}

export const Close: React.FC<props> = ({ onClick, buttonColor }) => {
  return (
    <CloseButton onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 48 48" width="18">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"
          fill={buttonColor}
        />
      </svg>
    </CloseButton>
  );
};
