import React from 'react';
import Link from 'next/link';
import { CustomIcon } from '@components/ui/Icon';
import { BannerIcon, BannerText, Contents, Wrapper } from './styled';

interface props {
  type: string;
  src: string;
  href?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const HoverBanner: React.FC<props> = ({ children, type, src, href, onClick, ...rest }) => {
  const contents = (
    <Contents>
      <BannerIcon>
        <CustomIcon src={src} alt="BannerIcon" title="BannerIcon" width="56px" height="56px" />
      </BannerIcon>
      <BannerText>{children}</BannerText>
    </Contents>
  );

  return (
    <Wrapper {...rest} onClick={onClick} data-mixpanel-action="click" data-mixpanel-evt="Hover Banner Click" data-mixpanel-name={type}>
      {href ? (
        <Link href={href} prefetch={false} target={'_blank'}>
          {contents}
        </Link>
      ) : (
        <>{contents}</>
      )}
    </Wrapper>
  );
};
