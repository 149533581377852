import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

type Props = Omit<IconProps, 'weight' | 'color'>;

export default function NewBadgeIcon({ className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 0H0V12H12V0ZM3 2V10H5V6L8 10H9V2H7V6L4 2H3Z" fill="#FF00CD" />
      </svg>
    </Styled.IconWrapper>
  );
}
