import styled from 'styled-components';
import { FlexRowAlignCenter } from '@components/wrapper';

export const Wrapper = styled.div<{ height?: number }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${(props) => props.height}px;
  position: initial;
  background-color: ${(props) => props.theme.colors.gnbBg};
  float: left;
`;

export const Container = styled(FlexRowAlignCenter)`
  justify-content: space-between;
  position: relative;
  width: 900px;
`;

export const Div = styled(FlexRowAlignCenter)`
  justify-content: space-between;
`;

export const RightButtons = styled(FlexRowAlignCenter)``;
