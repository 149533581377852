import styled from 'styled-components';
import { colors } from '@public/theme';

export const CalendarHeader = styled.div<{ isActive?: boolean }>`
  width: 72px;
  height: 40px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isActive &&
    `
        background-color: ${colors.gray.c13};
    `}
`;

export const ListWrapper = styled.div`
  border: 1px ${colors.gray.c8} solid;
  position: absolute;
  left: 0px;
  top: 40px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 8px 0px;
  max-height: 405px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListItem = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
        background-color: ${colors.pink.c2};
        color: white !important;
    `}
`;
