export enum ERROR_MESSAGE {
  /** 결제 금액이 변경되었습니다... */
  AMOUNT_CHANGED = 'payment:payment.validation.amountChanged',
  /** 주문하실 상품이 없습니다. */
  NO_PRODUCT_ORDER = 'payment:payment.validation.noProductOrder',
  /** 사용할 수 없는 쿠폰이 적용되어 있습니다. */
  NON_USABLE_COUPON = 'payment:payment.validation.nonUsableCoupon',
  /** 환율 변경 등으로 결제 금액이 변경되었습니다. */
  AMOUNT_CHANGED_GLOBAL = 'payment:payment.validation.amountChangedGlobal',
  /** 결제 처리 중 오류가 발생했습니다. */
  ERROR_PROCESSING_PAYMENT = 'payment:payment.validation.errorProdcessingPayment',
  /** 구매할 수 없는 상품이 포함되어 있습니다. */
  NOT_AVAILABLE_PRODUCT = 'payment:payment.validation.notAvailableProduct',
  /** 이메일 형식에 맞지 않습니다. */
  NOT_FORMAT_EMAIL = 'payment:payment.validation.notFormatEmail',
  /** 작품/프로젝트명을 적용하지 않은 상품이 있습니다. */
  NOT_APPLIED_PROJECT = 'payment:payment.validation.notAppliedProject',
  /** 사용자가 결제를 취소하였습니다. */
  USER_CANCEL_PAYMENT = 'payment:popup.userCancelPayment',
}

export class PaymentError extends Error {
  public loggingMessage: string;

  constructor(userMessage: string, customMessage: string) {
    super(userMessage);

    this.name = 'Payment Error';
    this.loggingMessage = customMessage;
    Object.setPrototypeOf(this, PaymentError.prototype);
  }
}
