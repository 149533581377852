import React from 'react';
import ThemeIndustry from './ThemeIndustry';
import ThemeMui from './ThemeMui';

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  return (
    <ThemeMui>
      <ThemeIndustry>{children}</ThemeIndustry>
    </ThemeMui>
  );
}
