import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function RightArrowIcon({ color = '#333', weight = 'normal', className, size, onClick }: IconProps) {
  // const strokeWidth = weight === 'thin' ? 1.5 : 2;

  return (
    <Styled.IconWrapper className={className} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.66667 12.6666C6.5109 12.6669 6.35994 12.6127 6.24 12.5133C6.10366 12.4002 6.0179 12.2376 6.00164 12.0613C5.98538 11.8849 6.03996 11.7093 6.15333 11.5733L9.14 7.99994L6.26 4.41994C6.14815 4.28221 6.09582 4.10558 6.11459 3.92915C6.13336 3.75273 6.22168 3.59106 6.36 3.47994C6.49944 3.35725 6.68375 3.29829 6.86852 3.31728C7.05328 3.33626 7.22175 3.43146 7.33333 3.57994L10.5533 7.57994C10.7555 7.82594 10.7555 8.18061 10.5533 8.42661L7.22 12.4266C7.08436 12.5902 6.87882 12.6794 6.66667 12.6666Z"
          fill={color}
        />
      </svg>
    </Styled.IconWrapper>
  );
}
