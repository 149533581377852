import React from 'react';
import { useTheme } from 'styled-components';

export default function GnbUserFilled({ className = '', size = 24 }) {
  const theme = useTheme();
  const color = theme.colors.icon;
  return (
    <div className={className}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3008_7848)">
          <circle cx="12" cy="7" r="3" fill={color} stroke={color} strokeWidth="2" />
          <path d="M12 14C7.58172 14 4 16.6863 4 20H20C20 16.6863 16.4183 14 12 14Z" fill={color} stroke={color} strokeWidth="2" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_3008_7848">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
