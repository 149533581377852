import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title } from '@components/common';

const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

const Styled = {
  Wrapper: styled.div`
    padding-bottom: 61px;
  `,
  CardGroup: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  Card: styled.div`
    width: 276px;
    height: 328px;
    background-image: linear-gradient(lightgray 172px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0),
      linear-gradient(lightgray 16px, transparent 0);
    background-repeat: repeat-y;
    background-size: 100% 328px, 180px 304px, 90px 304px, 32px 304px;
    background-position: 0px 0px, 0px 188px, 188px 214px, 0px 214px;
    animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  `,
};

export default function CardGroupSkeleton() {
  return (
    <Styled.Wrapper>
      <Title isSkeleton={true} isView={true} />
      <Styled.CardGroup>
        <Styled.Card />
        <Styled.Card />
        <Styled.Card />
      </Styled.CardGroup>
    </Styled.Wrapper>
  );
}
