import axios from 'axios';
import { PaymentError } from '@lib/error/payment-error';
import { RECEIPT_ISSUE_TYPE } from '@components/payment/constants';
import { CertificationError, CERTIFICATION_ERROR_MESSAGE } from '@lib/error/certification-error';
import { LanguageCodeEnum } from 'src/generated/graphql';
import {
  IBillingCardReturn,
  ICreateOrderReturn,
  ICreateKeyInput,
  IOrder,
  ICreatePaymentInput,
  IPayKeyInput,
  ISimplePaymentReturn,
  ICreateOrderPaymentResponse,
  ICompletedOrderResponse,
} from './model/IPayment';

const PAYMENT_URL = process.env.NEXT_PUBLIC_PAYMENT_URL;

/**
 * 빌링키를 생성하는 API를 호출하는 메소드 입니다.
 */
export const createBillingKey = async (param: ICreateKeyInput) => {
  let result = true;
  try {
    const params = new URLSearchParams();
    params.append('cardNumber', param.cardNumber);
    params.append('expiry', param.expiry);
    params.append('birth', param.birth.toString());
    params.append('pwd2digit', param.pwd2digit.toString());

    await axios.post(`${PAYMENT_URL}/reusable-card`, params, {
      withCredentials: true,
    });
  } catch (err) {
    result = false;
    console.error(`간편 결제 카드 등록 도중 오류 발생 (${err})`);
  }

  return result;
};

/** 유저의 빌링키를 전부 가져오는 API를 호출하는 메소드 입니다. */
export const getBillingKeys = async () => {
  let result: IBillingCardReturn[];

  try {
    const cardList = await axios.get(`${PAYMENT_URL}/reusable-card`, {
      withCredentials: true,
    });

    result = cardList.data;
  } catch (err) {
    result = [];
    console.error(`간편 결제 카드 조회 도중 오류 발생 (${err})`);
  }

  return result;
};

/** 유저 빌링키를 제거하는 API를 호출하는 메소드입니다. */
export const deleteBuillingKey = async (uid: string) => {
  let result = true;

  try {
    await axios.delete(`${PAYMENT_URL}/reusable-card`, {
      withCredentials: true,
      params: {
        uid: uid,
      },
    });
  } catch (err) {
    result = false;
    console.error(`간편 결제 카드 삭제 도중 오류 발생 (${err})`);
  }

  return result;
};

/** 빌링키로 결제를 진행하는 메소드 입니다. */
export const payBillingKey = async (param: IPayKeyInput) => {
  let result: ISimplePaymentReturn;

  try {
    const params = new URLSearchParams();
    params.append('merchantUid', param.merchantUid);
    params.append('descript', param.orderName);
    params.append('amount', param.amount.toString());

    const resultData: boolean = await axios.post(`${PAYMENT_URL}/cash/order/complete/${param.uid}`, params, {
      withCredentials: true,
    });

    if (!resultData) throw new Error('결제 도중 오류가 발생하였습니다.');

    result = {
      status: resultData,
      message: null,
    };
  } catch (err) {
    result = {
      status: false,
      message: err?.response?.data?.message,
    };
  }

  return result;
};

/** 결제 완료 후 처리 */
export const completedCashPayment = async (impUid: string) => {
  const params = new URLSearchParams();
  params.append('imp_uid', impUid);

  await axios.post(`${PAYMENT_URL}/cash/order/complete`, params, {
    withCredentials: true,
  });

  return true;
};

/** 에이콘 캐시 주문 생성 */
export const createCashPayment = async (param: ICreatePaymentInput) => {
  let result: ICreateOrderReturn;

  try {
    const params = new URLSearchParams();
    params.append('deposit', param.amount.toString());
    params.append('langCode', param.langCode);
    params.append('isDirect', param?.isDirect ? 'true' : 'false');

    const cashResult: any = await axios.post(`${PAYMENT_URL}/cash/order/create`, params, {
      withCredentials: true,
    });
    const orderId = cashResult.data.id;

    if (!orderId) throw new Error('주문서 생성 중 오류가 발생하였습니다.');

    result = {
      isSuccess: true,
      message: null,
      orderId: orderId,
    };
  } catch (err) {
    result = {
      isSuccess: false,
      message: err?.response?.data?.message || err,
      orderId: null,
    };
  }

  return result;
};

/** 보너스 포인트를 적용하기 위해 첫 번째 충전인지 조회 */
export const getIsFirstCharge = async () => {
  let result: boolean;

  try {
    const cashResult = await axios.get(`${PAYMENT_URL}/cash/is-first-charge`, {
      withCredentials: true,
    });

    result = cashResult.data;
  } catch (err) {
    result = false;
  }

  return result;
};

/** 상품 주문 생성 */
export const createOrderPayment = async (param: IOrder): Promise<ICreateOrderPaymentResponse> => {
  try {
    const { data } = await axios.post(`${PAYMENT_URL}/product/order/create`, param, {
      withCredentials: true,
    });
    const merchantUid = data.id;
    const godoOrderId = data.godoOrderId;
    const products = data?.products || [];

    if (!merchantUid) throw new PaymentError('주문서 생성 중 오류가 발생하였습니다.', 'merchantUid가 비어있습니다. ');

    return {
      merchantUid: merchantUid,
      godoOrderId: godoOrderId,
      products: products,
    };
  } catch (err) {
    if (err instanceof PaymentError) throw new PaymentError(err.message, `주문서 생성 (createOrderPayment) 실패(${err.loggingMessage})`);

    throw err;
  }
};

/** 상품 주문 결제 완료 처리 */
export const completedOrderPayment = async (impUid: string): Promise<ICompletedOrderResponse> => {
  try {
    const order = await axios.post(
      `${PAYMENT_URL}/product/order/complete`,
      { impUid: impUid },
      {
        withCredentials: true,
      },
    );

    const merchantUid = order.data.id;
    const godoOrderId = order.data.godoOrderId;
    const products = order.data?.products || [];

    if (!merchantUid) {
      throw new PaymentError('결제 완료 도중, 예상치 못한 예외가 발생하였습니다.', `merchantUid가 비어있습니다. (${order.data})`);
    }

    if (!godoOrderId) {
      throw new PaymentError('결제 완료 도중, 예상치 못한 예외가 발생하였습니다.', `godoOrderId가 비어있습니다. (${order.data})`);
    }

    return {
      merchantUid: merchantUid,
      godoOrderId: godoOrderId,
      products: products,
    };
  } catch (err) {
    if (err instanceof PaymentError) {
      throw new PaymentError(err.message, `결제 완료(completedOrderPayment) 도중, 예상치 못한 예외가 발생하였습니다. (${err.loggingMessage})`);
    }

    throw err;
  }
};

/** 현금 영수증 발급 */
export const issueCashReceipt = async (merchantUid: string, issueType: RECEIPT_ISSUE_TYPE, cellPhone: string, businessNumber: string, buyerName?: string, buyerEmail?: string) => {
  try {
    const receipt = await axios.post(
      `${PAYMENT_URL}/product/order/receipt`,
      {
        merchantUid: merchantUid,
        issueType: issueType,
        cellPhone: cellPhone,
        businessNumber: businessNumber,
        buyerName: buyerName,
        buyerEmail: buyerEmail,
      },
      {
        withCredentials: true,
      },
    );

    return receipt.data;
  } catch (err) {
    if (err instanceof PaymentError) {
      throw new PaymentError('현금 영수증 발급 도중 오류 발생', `현금 영수증 발급 (issueCashReceipt) 도중, 예상치 못한 예외가 발생하였습니다. (${err.message})`);
    }

    throw new Error(err);
  }
};

/** 주문 내역 조회 */
export const getOrderByImpUid = async (impUid: string) => {
  try {
    if (!impUid) throw new Error('getOrderByImpUid (impUid 가 존재하지 않습니다.)');
    const receipt = await axios.get(`${PAYMENT_URL}/product/order/${impUid}`, {
      withCredentials: true,
    });

    return receipt.data;
  } catch (err) {
    if (err instanceof PaymentError) {
      throw new PaymentError('주문 내역 조회 도중 오류 발생', `주문 내역 조회 도중, 예상치 못한 예외가 발생하였습니다. (${err.message})`);
    }

    throw new Error(err);
  }
};

/** 다날에 본인인증 요청 및 TID 조회 */
export const getCertificationId = async () => {
  try {
    const result = await axios.get(`${PAYMENT_URL}/acon-adult-member/verify?confirmURL=${PAYMENT_URL}/acon-adult-member/confirm`, {
      withCredentials: true,
    });

    if (!result.data?.TID) throw new Error(`본인인증 요청 시 TID가 존재하지 않음. (${result.data?.TID})`);

    return result.data.TID;
  } catch (err) {
    if (err?.response?.data?.message?.includes('이미 성인인증 된 사용자 입니다')) throw new CertificationError(CERTIFICATION_ERROR_MESSAGE.IS_ALREADY_ADULT, err);
    throw err;
  }
};

/** 본인인증이 완료되었는지 검증 */
export const checkCerification = async (language: LanguageCodeEnum) => {
  const result = await axios.get(`${PAYMENT_URL}/acon-adult-member/check?language=${language}`, {
    withCredentials: true,
  });

  if (!result.data) throw new Error(`본인인증 검증 과정에서 에러 발생. (${result.data})`);

  return result.data;
};

/** 글로벌몰 성인인증 등록 */
export const registerGlobalAdult = async (langageCode: LanguageCodeEnum, dob: string) => {
  if (!langageCode || !dob) throw new Error(`글로벌몰 성인인증 등록 중 필수값이 누락되었습니다. (langageCode: ${langageCode}, dob: ${dob})`);

  const result = await axios.post(
    `${PAYMENT_URL}/acon-adult-member`,
    {
      language: langageCode,
      dob: dob,
    },
    {
      withCredentials: true,
    },
  );

  if (!result.data) throw new Error(`글로벌몰 성인인증 등록 중 에러 발생. (${result.data})`);

  return result.data;
};
