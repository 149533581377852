import React, { ComponentProps } from 'react';
import { CustomText } from '..';
import * as Styled from './styled';

type Props = ComponentProps<typeof Styled.DarkGrayBorderButton>;

export const LightButton: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Styled.DarkGrayBorderButton type="button" {...rest}>
      <CustomText weight={500}>{children}</CustomText>
    </Styled.DarkGrayBorderButton>
  );
};
