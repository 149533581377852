import React from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { FlexColumn, FlexRowAlignCenter } from '@components/wrapper';
import { CloseIcon } from 'src/assets/icons';
import { CustomText } from '@components/ui';
import { ModalButton, ModalContainer, ModalContent } from './styled';
import { ModalType } from './type';
import ModalImage from './ModalImage';

type Props = {
  modalType: ModalType;
  onClickAllowHide: () => void;
  onClickHide: () => void;
};

export default function Modal({ modalType, onClickAllowHide, onClickHide }: Props) {
  const { t } = useTranslation(['modal']);

  const isModalBottomWrapper = modalType.buttonText || modalType.connectedUrl || modalType.text || modalType.subText;
  const handleModalButtonClick = () => window.open(modalType.connectedUrl);
  const isMobile = false;
  return (
    <ModalContainer isMobile={isMobile}>
      {modalType.image && (
        <>
          {modalType.connectedUrl ? (
            <Box
              onClick={handleModalButtonClick}
              data-mixpanel-action="click"
              data-mixpanel-evt="Button Click"
              data-mixpanel-action_name="popup link click"
              data-mixpanel-modal-id={modalType.id}
            >
              <ModalImage borderRadius={!isModalBottomWrapper ? 'all' : 'top'} src={modalType.image.azureStoragePath} />
            </Box>
          ) : (
            <ModalImage borderRadius={!isModalBottomWrapper ? 'all' : 'top'} src={modalType.image.azureStoragePath} />
          )}
        </>
      )}
      {isModalBottomWrapper && (
        <ModalContent isMobile={isMobile} isImage={Boolean(modalType.image)}>
          <FlexColumn className={isMobile ? 'pt-9 pb-9 pl-10 pr-10' : 'pt-5 pb-5 pl-6 pr-6'}>
            <div className={modalType.buttonText && modalType.connectedUrl ? (isMobile ? 'mb-6' : 'mb-3') : ''}>
              {modalType.text && (
                <CustomText
                  {...(!isMobile && {
                    size: 12,
                    letter: -0.01,
                    lineHeight: '150%',
                    color: '#000',
                    weight: 400,
                    className: modalType.subText ? 'mb-1' : '',
                  })}
                  dangerouslySetInnerHTML={{ __html: modalType.text.replaceAll(/(\n|\r\n)/g, '<br>') }}
                />
              )}
              {modalType.subText && (
                <CustomText
                  {...(!isMobile && {
                    size: 10,
                    letter: -0.01,
                    lineHeight: '150%',
                    color: '#000',
                    weight: 400,
                  })}
                >
                  {modalType.subText}
                </CustomText>
              )}
            </div>
            {modalType.buttonText && modalType.connectedUrl && (
              <ModalButton
                isMobile={isMobile}
                onClick={() => window.open(modalType.connectedUrl)}
                type="button"
                data-mixpanel-action="click"
                data-mixpanel-evt="Button Click"
                data-mixpanel-action_name="popup link click"
                data-mixpanel-modal-id={modalType.id}
              >
                <CustomText numLines={1} color={'#333'} size={isMobile ? 28 : 12} weight={500}>
                  {modalType.buttonText}
                </CustomText>
              </ModalButton>
            )}
          </FlexColumn>
        </ModalContent>
      )}
      <FlexRowAlignCenter className={`justify-between ${isMobile ? 'pt-5 pl-5 pr-5' : 'pt-2 pl-2 pr-2'}`}>
        <button
          onClick={onClickAllowHide}
          data-mixpanel-action="click"
          data-mixpanel-evt="Button Click"
          data-mixpanel-action_name="do not show again"
          data-mixpanel-modal-id={modalType.id}
        >
          <CustomText size={isMobile ? 32 : 14} weight={500} color={'#fff'}>
            {t('modal:dontShow')}
          </CustomText>
        </button>
        <button
          onClick={onClickHide}
          className={'flex items-center'}
          data-mixpanel-action="click"
          data-mixpanel-evt="Button Click"
          data-mixpanel-action_name="close popup"
          data-mixpanel-modal-id={modalType.id}
        >
          <CustomText className={'mr-1'} size={isMobile ? 32 : 14} weight={500} color={'#fff'}>
            {t('modal:close')}
          </CustomText>
          <CloseIcon customStroke={isMobile ? 3 : 1.333} color={'#fff'} size={isMobile ? 40 : 16} />
        </button>
      </FlexRowAlignCenter>
    </ModalContainer>
  );
}
