import React from 'react';
import Styled from 'styled-components';
import { CustomIcon } from '@components/ui/Icon';
import * as ga from '@lib/ga';
import { useCart, useUserInfo } from '@hooks';
import { logEvent } from '@hooks/logger';
import { CURRENCY } from '@lib/iamport/constants';
import { UserInfoStatusEnum } from '../../../../src/provider';
import { AddCartFromScrapLog } from '@lib/mixpanel/events';

export const AddCart = Styled.button<{ isCart: boolean; isScrapEdit: boolean; isSale?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  padding: 6px;
  margin-bottom: 12px;
  margin-right: 6px;

  ${(props) => (props.isScrapEdit ? 'opacity: 0.3' : '1')};

  cursor: ${(props) => (props.isSale ? 'pointer' : 'not-allowed')};

  ${(props) =>
    !props.isCart &&
    `
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
    `}
  border-radius: 6px;

  background: ${(props) => (props.isCart ? '#EEEEEE;' : '#FFFFFF;')}
`;

interface Props {
  isScrapEdit: boolean;
  onSale: boolean;
  goodsNo: string;
  title: string;
  price: number;
  onAddCart: any;
  className?: string;
  legacy?: boolean;
  iconSize?: string;
}

export const AddCartButton: React.FC<Props> = ({ isScrapEdit, onSale, goodsNo, title, price, onAddCart, legacy = true, className = '', iconSize = '20px', ...rest }) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isCart, setIsCart, addCart] = useCart(Number(goodsNo), false);
  const { goLoginIfNecessary } = useUserInfo();

  // 장바구니 버튼 클릭 이벤트 처리기 메소드
  const handleCartButtonClick = async () => {
    // 로그인 검증 후, 로그인 페이지로 이동
    if (goLoginIfNecessary() !== UserInfoStatusEnum.authenticated || !onSale) return;

    if (!isScrapEdit) AddCartFromScrapLog({ productNos: [Number(goodsNo)] });

    // GA
    ga.event({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [
            {
              id: goodsNo,
              quantity: 1,
            },
          ],
        },
      },
    });

    /* GA4 */
    logEvent('add_to_cart', {
      goods_no: goodsNo,
    });

    // GA4 전자상거래 장바구니 담기 이벤트
    const addToCartProperties = {
      currency: CURRENCY.KRW,
      value: price,
      items: [
        {
          index: 0,
          item_id: goodsNo,
          item_name: title,
          price: price,
        },
      ],
    };
    logEvent('add_to_cart', addToCartProperties, false);

    // 장바구니 추가
    await addCart();

    // 카트 담김 이벤트
    onAddCart();
  };

  // 장바구니 아이콘
  const cartIconSrc = (() => {
    if (legacy) return `/icons/mypage/${isCart ? 'cart_full' : 'cart'}.png`;

    return `/icons/mypage/userHome/${isCart ? 'cart-black-icon' : 'cart-white-icon'}.png`;
  })();

  return (
    <AddCart className={className} isCart={isCart} isScrapEdit={false} isSale={onSale} onClick={handleCartButtonClick} {...rest}>
      <CustomIcon src={cartIconSrc} alt="cart" title="cart" width={iconSize} height={iconSize} priority={true} />
    </AddCart>
  );
};
