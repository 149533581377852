import { captureException as captureExceptionOriginal } from '@sentry/nextjs';
import { ApolloError } from '@apollo/client/errors';
import { ScopeContext } from '@sentry/types/types/scope';
import { ServerParseError } from '@apollo/client';
import { AxiosError } from 'axios';

export interface ExtraError extends Partial<ApolloError> {
  extra?: any;
  route?: string;
  status?: number;
  code?: string;
  type?: string;
  axiosError?: AxiosError;
}
const extractExtra = (extra): any => (!extra ? {} : Object.fromEntries(Object.entries(extra).map(([k, v]) => [k, typeof v === 'string' ? v : JSON.stringify(v, null, 2)])));

export function captureException(error: ExtraError, additionalInfo: Partial<ScopeContext> = {}) {
  try {
    const extra = extractExtra(error.extra);
    const { extra: extractedExtra, ...sanitizedAdditionalInfo } = additionalInfo;
    delete error.extra;
    if (error.axiosError) {
      error.name = 'AxiosError';
      try {
        error.message = error.axiosError.response.statusText;
      } catch (e){/*handled*/}
      if (!sanitizedAdditionalInfo.contexts) sanitizedAdditionalInfo.contexts = {};
      sanitizedAdditionalInfo.contexts.response = {
        status_code: error.status,
      };
    }
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      error.name = 'ApolloGraphQLError';
      error.message = error.graphQLErrors.map(({ message }) => message).join('\n');
    }
    if (error.networkError) {
      error.name = 'ApolloNetworkError';
      error.message = error.networkError.message;
      const networkError = error.networkError as ServerParseError;
      if (networkError?.response) {
        if (!sanitizedAdditionalInfo.contexts) sanitizedAdditionalInfo.contexts = {};
        sanitizedAdditionalInfo.contexts.response = {
          status_code: networkError.statusCode || networkError.response.status,
          type: networkError.response.type,
        };
        try {
          extractedExtra.url = networkError.response.url;
        } catch (e){/*handled*/}
      }
    }
    if (error.clientErrors) {
      error.name = 'ApolloClientError';
      error.message = (Array.isArray(error.clientErrors) ? error.clientErrors.map((message) => JSON.stringify(message, null, 2)).join('\n\n') : error.clientErrors) as string;
    }
    if (error.name && !error.name.startsWith('Apollo')) extra.name = error.name;
    if (error.route) extra.route = error.route;
    if (error.status) extra.status = error.status;
    if (error.code) extra.code = error.code;
    if (extractedExtra) Object.assign(extra, extractedExtra);
    if (typeof window !== 'undefined') {
      try {
        if (window.localStorage.getItem('_DEBUG_ACON3D_') === 'true') {
          console.log('captureException', [error, {
            ...sanitizedAdditionalInfo,
            extra,
          }]);
        }
      } catch (e){ /* handled */}
    }
    return captureExceptionOriginal(error, { ...sanitizedAdditionalInfo, extra });
  } catch (e){
    captureExceptionOriginal(e);
  }
}
