import styled from 'styled-components';
import { LANGUAGE_CODE } from 'src/constants';
import { CustomText } from '@components/ui';
import { colors } from '@public/theme';

export const CategoryContainer = styled.div`
  padding: 10px 24px 3px 24px;
`;
export const BrandHome = styled.div`
  padding: 14px 20px 0px 20px;
  height: 51px;
  background-color: #fcfcfd;
  border-bottom: 1px #e0e0e0 solid;
  cursor: pointer;
`;

export const SideBarContainer = styled.div<{ lang: string; top: number }>`
  box-shadow: 0px 6px 14px rgb(0 0 0 / 6%);
  box-sizing: border-box;
  border: 1px #e0e0e0 solid;
  position: absolute;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;

  ${(props) =>
    props.lang === LANGUAGE_CODE.EN
      ? `
            margin-left: -266px;
            margin-top: ${props.top || 0}px;
            width: 238px;
        `
      : `
            margin-left: -250px;
            margin-top: ${props.top || 0}px;
            width: 222px;
        `}
`;

export const CategoryMainItems = styled.div`
  padding: 14px 0px;
  border-bottom: 1px ${colors.gray.c1} solid;

  &:last-child {
    border: none;
  }
`;

export const CategoryMainItem = styled.div<{ isActive: boolean; heightSize: number }>`
  padding: 5px 0px;
  transition: height 0.5s ease;
  height: 21px;
  overflow: hidden;
  box-sizing: content-box;

  ${(props) =>
    props.isActive &&
    `
        height: ${props.heightSize}px;
    `}
`;

export const CategoryMainTitleText = styled(CustomText).attrs(() => ({
  style: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  size: 14,
}))``;

export const CategoryMainTitle = styled.div<{ isLink: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  ${(props) => props.isLink && 'cursor: pointer;'}
  &:hover ${CategoryMainTitleText} {
    font-weight: bold !important;
  }
`;

export const CategorySubItems = styled.div`
  padding-left: 16px;
`;

export const CategorySubItemText = styled(CustomText).attrs(() => ({
  style: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  size: 13,
}))`
  &:hover {
    font-weight: bold !important;
  }
`;

export const CategorySubItem = styled.div`
  padding: 9px 0px;
  cursor: pointer;

  &:hover ${CategorySubItemText} {
    font-weight: bold !important;
  }
`;

export const MainCategoryTab = styled.div<{ isChecked: boolean }>`
  width: 50%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    !props.isChecked &&
    `
    background-color: ${colors.gray.c20};
  `}
`;

export const MainCategoryText = styled(CustomText)`
  position: relative;
`;
