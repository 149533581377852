import React, { MouseEventHandler, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

type Colors = 'confirm' | 'cancel';
type Props = {
  width?: number;
  height?: number;
  className?: string;
  size?: 'small' | 'regular' | 'large';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: Colors;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  loading?: boolean;
};

const Button = styled.button<{ width: number; height?: number; color: Colors }>`
  ${(props) => `width: ${props.width}px;`}
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #7c7c7c;
  height: ${(props) => (props.height ? props.height : 40)}px;
  border-radius: 3px;

  &:hover {
    ${(props) => props.color === 'confirm' && 'background-color: #222;'}
    ${(props) => props.color === 'cancel' && 'background-color: #fafafc;'}
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${(props) => `
    ${props.color === 'confirm' && 'background-color: #333; color: #fff; border: 0;'}
    ${props.color === 'cancel' && 'background-color: #fff;'}
  `}
`;

export default function LoadingButton({ size, onClick, width, height, disabled, loading, color, className, type = 'button', children, ...rest }: Props) {
  width = width ? width : size === 'regular' ? 216 : size === 'small' ? 120 : 400;

  if (loading) disabled = true;

  // [TODO] : mui theme 설정으로 해당 값을 적용해야 함
  const circleColor = color === 'confirm' ? '#fff' : '#333';

  return (
    <Button type={type} onClick={onClick} width={width} height={height} disabled={disabled} color={color} className={className} {...rest}>
      {!loading && children}
      {loading && <CircularProgress size={'1.5em'} style={{ color: circleColor }} />}
    </Button>
  );
}

// #3a3e94색
const NavyBR4Button = styled.button<{ width: number; height?: number; color: Colors }>`
  ${(props) => `width: ${props.width}px;`}
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #3a3e94;
  height: ${(props) => (props.height ? props.height : 40)}px;
  border-radius: 4px;
  padding: 10px 0px;

  &:hover {
    ${(props) => props.color === 'confirm' && 'background-color: #222;'}
    ${(props) => props.color === 'cancel' && 'background-color: #fafafc;'}
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${(props) => `
    ${props.color === 'confirm' && 'background-color: #333; color: #fff; border: 0;'}
    ${props.color === 'cancel' && 'background-color: #fff;'}
  `}
`;

export function NavyBR4LoadingButton({ size, onClick, width, height, disabled, loading, color, className, type = 'button', children }: Props) {
  width = width ? width : size === 'regular' ? 216 : size === 'small' ? 120 : 400;

  if (loading) disabled = true;

  // [TODO] : mui theme 설정으로 해당 값을 적용해야 함
  const circleColor = color === 'confirm' ? '#fff' : '#333';

  return (
    <NavyBR4Button type={type} onClick={onClick} width={width} height={height} disabled={disabled} color={color} className={className}>
      {!loading && children}
      {loading && <CircularProgress size={'1.5em'} style={{ color: circleColor }} />}
    </NavyBR4Button>
  );
}

// 비활성화시 opacity 0.5
const OpacityButton = styled.button<{ width: number; height?: number; color: Colors }>`
  ${(props) => `width: ${props.width}px;`}
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #7c7c7c;
  height: ${(props) => (props.height ? props.height : 40)}px;
  border-radius: 4px;

  &:hover {
    ${(props) => props.color === 'confirm' && 'background-color: #222;'}
    ${(props) => props.color === 'cancel' && 'background-color: #fafafc;'}
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${(props) => `
    ${props.color === 'confirm' && 'background-color: #333; color: #fff; border: 0;'}
    ${props.color === 'cancel' && 'background-color: #fff;'}
  `}
`;

export function OpacityLoadingButton({ size, onClick, width, height, disabled, loading, color, className, type = 'button', children }: Props) {
  width = width ? width : size === 'regular' ? 216 : size === 'small' ? 120 : 400;

  if (loading) disabled = true;

  // [TODO] : mui theme 설정으로 해당 값을 적용해야 함
  const circleColor = color === 'confirm' ? '#fff' : '#333';

  return (
    <OpacityButton type={type} onClick={onClick} width={width} height={height} disabled={disabled} color={color} className={className}>
      {!loading && children}
      {loading && <CircularProgress size={'1.5em'} style={{ color: circleColor }} />}
    </OpacityButton>
  );
}
