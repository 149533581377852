import React, { useState, ComponentProps, SyntheticEvent, SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import { CustomText } from '@components/ui';
import { Validator } from '@lib/form';

export const InputContainer = styled.div({ marginBottom: '36px', ':last-child': { marginBottom: 0 } });

interface InputProps extends ComponentProps<'input'> {
  valid?: boolean;
  width?: string;
  marginRight?: number;
}
export const PrivateInput = styled.input<InputProps>`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #7c7c7c;
  }
  :-ms-input-placeholder {
    color: #7c7c7c;
  }
`;
export const Input = styled(PrivateInput)<InputProps>(({ valid, width, marginRight }) => ({
  color: '#333',
  width: width ? width : '100%',
  height: 40,
  padding: '10.5px 16px',
  borderRadius: '3px',
  border: valid === false ? '1px solid #f300ba' : '1px solid #cdcdcd',
  boxSizing: 'border-box' as const,
  marginRight: marginRight ? `${marginRight}px` : '0px',
  fontSize: 12,
  placeholderTextColor: 'red',
}));

export const InputLabel = styled.div({
  color: '#333',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '100%',
  marginBottom: '16px',
  '::before': {
    width: '4px',
    height: '4px',
    display: 'block',
    backgroundColor: '#ff00c3',
    borderRadius: '10px',
    content: '""',
    float: 'left',
    marginTop: '6px',
    marginRight: '10px',
  },
});

export const InputInfo = styled(CustomText).attrs({ size: 12, color: '#7c7c7c', lineHeight: '130%' })({
  marginBottom: '12px',
  marginTop: '12px',
});
export const Validation = styled(CustomText).attrs({ size: 12 })({ marginTop: '12px' });

export interface InputState {
  value: [any, Dispatch<SetStateAction<any>>];
  valid: [boolean, Dispatch<SetStateAction<boolean>>];
  info: [string, Dispatch<SetStateAction<string>>];
}

interface Props extends ComponentProps<typeof Input> {
  label?: string;
  maxLength?: number;
  info?: string;
  message?: string;
  style?: React.CSSProperties;
  state?: InputState;
  validator?: Validator;
}

const ValidationInput: React.FC<Props> = (props) => {
  const _state: InputState = {
    value: useState(),
    valid: useState(true),
    info: useState(''),
  };

  const { maxLength, label, info, style, state = _state, validator, onChange, ...rest } = props;

  const [value, setValue] = state.value;
  const [valid, setValid] = state.valid;
  const [message, setMessage] = state.info;

  const color: string = valid === false ? '#f300ba' : '#3a3e94';

  const _onChange = async (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const newValue = target.value;

    setValue(newValue);

    if (validator) {
      const r = await validator(newValue);
      setValid(r.valid);
      setMessage(r.info);
    }

    if (onChange) onChange(e);
  };

  return (
    <InputContainer style={style}>
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        {...(maxLength && {
          maxLength,
        })}
        value={value}
        valid={valid}
        onChange={_onChange}
        {...(props?.valueName && {
          'data-mixpanel-action': 'click',
          'data-mixpanel-evt': 'Button Click',
          'data-mixpanel-action_name': props.valueName,
          'data-mixpanel-page_name': 'signup',
          'data-mixpanel-collect-once': 'true',
        })}
        {...rest}
      />
      {info && <InputInfo>{info}</InputInfo>}
      {message && <Validation color={color}>{message}</Validation>}
    </InputContainer>
  );
};

export default ValidationInput;
