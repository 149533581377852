import React, { useRef } from 'react';
import * as Styled from './styled';

type InputProps = {
  placeholder?: string;
  value: string;
  readonly?: boolean;
  onChange?: (value: string) => void;
};

export const NoBorderInput = ({ ...rest }) => {
  return <Styled.BaseInput {...rest} />;
};

export const BorderInput: React.FC<InputProps> = ({ placeholder, value, onChange, readonly }) => {
  const handleInputChange = (e) => {
    onChange && onChange(e.target.value);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handlePlaceHolderClick = () => {
    inputRef.current.focus();
  };
  return (
    <Styled.InputContainer>
      {!value && placeholder && (
        <div className={'mt-1'} onClick={handlePlaceHolderClick}>
          <Styled.Placeholder>{placeholder}</Styled.Placeholder>
        </div>
      )}
      <Styled.Input value={value} onChange={handleInputChange} ref={inputRef} readOnly={readonly} />
    </Styled.InputContainer>
  );
};
