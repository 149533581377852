import React from 'react';
import { CustomText } from '@components/ui';
import { numberWithCommas } from '@lib';
import ViewIcon from 'src/assets/icons/ViewIcon';

type Props = {
  count: number;
};

export default function ViewCount({ count }: Props) {
  return (
    <div className={'flex gap-1'}>
      <ViewIcon />
      <CustomText size={12} color={'#a0a0a0'}>
        {numberWithCommas(count)}
      </CustomText>
    </div>
  );
}
