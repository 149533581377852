import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

export default function LeftShadowArrowIcon({ color = '#333', className, size, onClick }: IconProps) {
  // const strokeWidth = weight === 'thin' ? 1.5 : 2;

  return (
    <Styled.IconWrapper className={className} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.66 38.0001C27.0548 38.0022 26.4813 37.7301 26.1 37.2601L16.44 25.2601C15.8334 24.5221 15.8334 23.4581 16.44 22.7201L26.44 10.7201C27.1469 9.86959 28.4095 9.75318 29.26 10.4601C30.1105 11.167 30.2269 12.4296 29.52 13.2801L20.58 24.0001L29.22 34.7201C29.7194 35.3196 29.8246 36.1547 29.4895 36.8593C29.1544 37.5638 28.4402 38.0092 27.66 38.0001Z"
          fill={color}
        />
        <defs>
          <filter id="filter0_d_355_2167" x="7.98505" y="5.99756" width="29.9975" height="44.0027" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_2167" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_2167" result="shape" />
          </filter>
        </defs>
      </svg>
    </Styled.IconWrapper>
  );
}
