import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';
import { CustomLink } from '@components/ui';
import * as Styled from './styled';

export const SubContainerItem = ({ item, onClick }) => {
  const [isTwoLine, setIsTwoLine] = useState(false);
  const itemRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (itemRef) {
      const itemHeight = itemRef.current?.clientHeight;
      setIsTwoLine(itemHeight > 32);
    }
  }, [itemRef]);

  // 카테고리 명을 가져오는 메소드입니다.
  const getCategoryName = (item) => {
    // 카테고리 현재 규칙의 경우, #{imageName}#{categoryName} 으로 되어있어 이미지 태그로 치환해주어야 합니다.
    // 카테고리명 정규식 (# 으로 묶여진 항목은 이미지 파일명)
    const cateRegex = /#(.*?)#/g;
    // 이미지 명
    let imgName = item.name.match(cateRegex);
    // 이미지명 확보
    imgName = imgName ? imgName[0].replace(/#/g, '') : imgName;
    imgName = imgName ? '/icons/category/' + imgName + '.png' : imgName;

    // 카테고리 명 변환
    return imgName ? (
      <>
        <Image alt="" src={imgName} width="16" height="16" />
        <Styled.SubCategoryTitle isTwoLine={isTwoLine} onClick={onClick}>
          {item.name.replace(cateRegex, '')}
        </Styled.SubCategoryTitle>
      </>
    ) : (
      <Styled.SubCategoryTitle isTwoLine={isTwoLine} onClick={onClick}>
        {item.name}
      </Styled.SubCategoryTitle>
    );
  };

  return (
    <Styled.SubCategoryGroupItem ref={itemRef}>
      <CustomLink className={'w-full'} href={`/category/${item.code}`}>
        {getCategoryName(item)}
      </CustomLink>
    </Styled.SubCategoryGroupItem>
  );
};
