import styled from 'styled-components';

export const Contents = styled.div`
  border-bottom: 1px #333 solid;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 7px 0px 16px;
  cursor: pointer;
  transition: 0.3s;
`;

export const BannerText = styled.div`
  width: 50%;
  font-size: 22px;
  font-weight: 700;
  word-break: keep-all;
  line-height: 28px;
  color: #333;
`;

export const Wrapper = styled.div`
  width: 276px;
  height: 156px;
  overflow: hidden;
  background-color: #fafafd;
  box-shadow: 0px 2px 4px #eaeaea;
  border: 1px #eaeaea solid;
  padding: 40px 38px 39px 38px;
  border-radius: 4px;

  &:hover ${Contents} {
    transform: scale(1.1);
  }
`;

export const BannerIcon = styled.div``;
