/**
 * 무한 디버거로 방해
 */
export const preventToUseDeveloperTool = () => {
  // infinite debugger
  // eslint-disable-next-line no-eval
  setInterval(() => eval('debugger;'), 0);
};
/**
 * 우클릭 방지
 */
export const preventToUseContextMenu = () => {
  document.oncontextmenu = function () {
    return false;
  };
  document.ondragstart = function () {
    return false;
  };
};

export const preventAllAbuse = () => {
  try {
    if (window.localStorage._CARPENSTREET_ === '떡볶이국물에꼬마김밥') return;
    // alertDeveloperTool();
    preventToUseDeveloperTool();
    preventToUseContextMenu();
    // disableConsoleOutput();
  } catch (e) {
    /* handled error */
  }
};
