import React from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { ListContainer, RemoveAllItemButton, SearchFooter, SearchFooterLink } from '@components/layout/Header/components/List/List.styles';
import { GET_SEARCH_KEYWORD_LIST } from '@api';
import { convertLangCodeForDB } from '@lib';
import { RightArrowIcon } from 'src/assets/icons';
import { FlexRowAlignCenter } from '@components/wrapper';
import { CustomText } from '@components/ui';
import { CustomIcon } from '@components/ui/Icon';
import Group from '../Group/Group';
import { IListProps } from './List.types';

const List = ({ isMobile, recentItems, onBlurInput, onClickRemoveAllItemButton }: IListProps): React.ReactElement => {
  // 번역도구
  const { t, i18n } = useTranslation(['menu']);
  // 데이터
  const { data: popularItems } = useQuery(GET_SEARCH_KEYWORD_LIST, {
    variables: {
      lang: convertLangCodeForDB(i18n.language),
    },
  });
  return (
    <ListContainer isMobile={isMobile} onMouseDown={(e) => e.preventDefault()}>
      {recentItems.length !== 0 && (
        <>
          <RemoveAllItemButton onClick={onClickRemoveAllItemButton}>
            {!isMobile && <Image src="/icons/trash-can-icon.svg" width="14" height="14" alt="" />}
            {t('menu:gnb.search.removeAll')}
            {isMobile && <CustomIcon src="/imgs/event/only-acon/close-gray.png" alt="close" title="close" width="11px" height="11px" />}
          </RemoveAllItemButton>
          <Group title={t('menu:gnb.search.recent')} items={recentItems} onBlurInput={onBlurInput} />
        </>
      )}
      <Group
        isPopulKeyword={true}
        title={t('menu:gnb.search.popular')}
        items={popularItems && popularItems.searchKeywordList && popularItems.searchKeywordList.map((x) => x.keyword)}
        onBlurInput={onBlurInput}
      />
      <SearchFooter>
        <Link href={isMobile ? '/category' : '#category'} prefetch={false} legacyBehavior>
          <SearchFooterLink
            className="search__category"
            onClick={() => {
              onBlurInput();
            }}
          >
            <FlexRowAlignCenter>
              <CustomText className="mr-1" size={14} weight={600} color={'#4E4EFF'}>
                {t('menu:gnb.search.findByCategory')}
              </CustomText>
              <RightArrowIcon size={17} color={'#4E4EFF'} />
            </FlexRowAlignCenter>
          </SearchFooterLink>
        </Link>
      </SearchFooter>
    </ListContainer>
  );
};

export default List;
