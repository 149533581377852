import gql from 'graphql-tag';

export const UPLOAD_BLOB_STORAGE = gql`
  mutation UPLOAD_BLOB_STORAGE($targetDirectory: String!, $temporaryFilePath: String!, $fileName: String!) {
    uploadBlobStorage(inputs: { targetDirectory: $targetDirectory, temporaryFilePath: $temporaryFilePath, fileName: $fileName }) {
      azureStoragePath
      id
    }
  }
`;

export const UPDATE_GODO_HIT_COUNT = gql`
  mutation ($goodsNo: String!) {
    updateGodoHitCount(goodsNo: $goodsNo)
  }
`;

export const PASSWORD_CHANGE_SEND_EMAIL = gql`
  mutation ($lang: String!, $userId: String!) {
    passwordChangeSendEmail(lang: $lang, userId: $userId)
  }
`;

export const PASSWORD_CHANGE = gql`
  mutation ($key: String!, $password: String!) {
    godoPasswordChange(key: $key, password: $password)
  }
`;

export const SEND_AUTHENTICATION_CODE = gql`
  mutation ($email: String!, $lang: String!) {
    sendAuthenticationCode(email: $email, lang: $lang)
  }
`;

export const AUTHENTICATE = gql`
  mutation ($email: String!, $code: String!) {
    authenticate(email: $email, code: $code)
  }
`;

export const CREATE_FRIEND = gql`
  mutation CREATE_FRIEND($langCode: String!, $godoMemNo: Int!, $code: String!) {
    createFriend(langCode: $langCode, godoMemNo: $godoMemNo, code: $code)
  }
`;

export const CREATE_BUSINESS = gql`
  mutation CREATE_BUSINESS($bizNum: String!, $bizName: String!, $representativeName: String!, $openDate: String!) {
    createBusiness(bizNum: $bizNum, bizName: $bizName, representativeName: $representativeName, openDate: $openDate)
  }
`;

export const ADD_NOTIFICATION = gql`
  mutation ADD_NOTIFICATION($type: String!, $message: String) {
    createAconNotification(type: $type, message: $message) {
      id
      memNo
      type
      message
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DELETE_NOTIFICATION($type: [String!]!) {
    deleteAconNotification(type: $type)
  }
`;

export const FAVORITE_REVIEW = gql`
  mutation SetFavoriteReview($reviewId: Int!) {
    setFavoriteReview(reviewId: $reviewId)
  }
`;

export const REGISTER_REVIEW_V2 = gql`
  mutation RegisterReviewV2($input: RegisterReviewInput!) {
    registerReviewV2(input: $input) {
      id
      created
    }
  }
`;

export const UPDATE_REVIEW_V2 = gql`
  mutation UpdateReviewV2($input: UpdateReviewInput!) {
    updateReviewV2(input: $input) {
      id
    }
  }
`;
