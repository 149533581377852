import styled from 'styled-components';
import { CustomText } from '@components/ui';

export const Container = styled.div`
  background-color: #fcfcfd;
`;

export const Navi = styled.div`
  border-top: 1px #e3e3e3 solid;
  border-bottom: 1px #e3e3e3 solid;
  height: 61px;
  padding: 13px 0px;
`;

export const Menu = styled.div`
  padding-top: 5px;
  display: flex;
`;

export const MenuText = styled(CustomText).attrs({
  lineHeight: '21px',
})`
  & > a {
    padding: 0px 16px;
    border-right: 1px #e0e0e0 solid;
    font-size: 14px;
    font-weight: ${(props) => (props.weight ? props.weight : 400)};
    color: #535353;
  }

  &:first-child > a {
    padding-left: 0px;
  }

  &:last-child > a {
    padding-right: 0px;
    border: none;
  }
`;

export const CenterWrap = styled.div`
  margin: 0px auto;
  width: 900px;
  display: flex;
  justify-content: space-between;
`;

export const Option = styled.div`
  display: flex;
`;

export const Copyright = styled.div``;

export const Social = styled.div`
  padding-top: 6px;

  & > a {
    margin-right: 16px;
  }

  &>a: last-child {
    margin-right: 0px;
  }
`;

export const Global = styled.div`
  margin-left: 42px;
  display: flex;
  padding-top: 1px;
`;

export const SelectWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    background-image: url('/icons/footer/dropdown-icon.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 18px;
    height: 26px;
    top: 6px;
    right: 1px;
    font-size: 8px;
    z-index: 1;
    pointer-events: none;
    color: #333;
  }
`;

export const GlobalSelect = styled.select`
  vertical-align: bottom;
  padding: 7px 36px 5px 23px;
  border: 1px solid #cdcdcd;
  margin-left: 13px;
  text-align-last: center;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  & > option {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const InfoWrap = styled.div`
  padding: 54px 0px 91px 0px;
`;

export const Logo = styled.div`
  margin-bottom: 17px;
`;

export const CompanyInfo = styled.div``;

export const CompanyInfoLine = styled.div`
  &:last-child > span:last-child {
    font-weight: 500;
  }
`;

export const CompanyInfoText = styled.span`
  color: #7c7c7c;
  margin-right: 12px;
  font-size: 11px;

  & > a {
    color: #7c7c7c;
    font-weight: bold;
    text-decoration: underline;
  }
`;
